import {notify} from "notiwind";
import {defineStore, storeToRefs} from "pinia";
import {v4 as uuidv4} from "uuid";
import type {Ref} from "vue";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import axios from "axios";
import type {
  Assistant,
  Channel,
  ChannelParticipant,
  ContentObject,
  CostViewer,
  DataAttribute,
  DataAttributeNormalize,
  DataException,
  DataExceptionDTO,
  DataForm,
  DataFormState,
  DataObject,
  DataObjectDTO,
  DocumentFamily,
  DocumentFeatureSetDTO,
  DocumentViewState,
  FeatureSet,
  GuidanceSet,
  Message,
  MessageBlock,
  MessageTemplate,
  Store,
  Taxon,
  Taxonomy,
  User,
  Workspace,
  WorkspaceAdditions,
  WorkspaceDeletions,
  WorkspaceDocument,
  WorkspaceStorage,
  WorkspaceUpdate,
  WorkspaceUpdates,
} from "~/model";
import {DataAttributeTypeAtCreation} from "~/model";
import type {ContentNode, SelectedTag} from "~/components/document/document";
import router from "~/router/router";
import appStore from "~/store/index";
import {createDocumentViewerStore} from "~/store/useDocumentView";
import type {TagMetadata} from "~/store/useProject";
import {log} from "~/utils/logger";
import {RefHelper} from "~/utils/ref-utils";
import {handleError, updateHandler} from "~/utils/error-handler";
import type {LoadingEvent} from "~/store/usePlatform";
import KodexaDeleteConfirm from "~/components/kodexa-confirm.vue";
import KodexaConfirm from "~/components/kodexa-confirm.vue";
import type {MessageContext} from "~/store/useChannel";
import {
  deleteDocumentFamily,
  getChangeSequenceForDocumentFamily,
  getDocumentFamily,
  getDocumentFamilyExternalData, toggleGuidanceOnDocumentFamily,
} from "~/api/document-families/document-families";
import {
  addDocumentFamilyToWorkspace,
  createWorkspace,
  deleteWorkspace,
  getChangeSequenceForWorkspace,
  getWorkspace,
  listWorkspaceDocumentFamilies,
  listWorkspaces,
  removeDocumentFamilyFromWorkspace,
  updateWorkspace,
  updateWorkspaceObjects,
} from "~/api/workspaces/workspaces";
import {normalize} from "~/api/platform-overview/platform-overview";
import {lockFamily, unlockFamily} from "~/api/stores/stores";
import {createChannel} from "~/api/channel/channel";
import {listDataObjects} from "~/api/data-objects/data-objects";
import KodexaAttributeProperties from "~/components/kodexa-attribute-properties.vue";

export interface TagInstance {
  uuid: string;
  taxon: Taxon;
  taxonomy: Taxonomy;
  pageNumber: number;
  tagData: any;
  value: string;
  confidence: number;
  lineNumber: number;
  cellIndex: string;
  groupUuid: string;
  ownerUri: string;
  nodes: ContentNode[];
  isPage: boolean;
}

export interface DocumentViewer {
  documentFamilyId: string;
  id: string;
  viewType: "document";
  ephemeral: boolean;
  isSidecar: boolean;
  currentPage: number;
  focusTagUuid: string | undefined;
  /**
   * If a document view has an executionId that means that we are looking to
   * show the progress of the running execution and then the resultant document
   * from the content object of that execution
   *
   * We will use the executionStore to monitor the execution and then update
   */
  executionId: string | undefined;
}

export interface DocumentFeatureSet {
  contentObjectId: string;
  featureSet: FeatureSet;
}

export interface DataFormViewer {
  dataFormRef: string;
  id: string;
  documentFamilyIds: string[];
  viewType: "dataForm";
  ephemeral: boolean;
  isSidecar: boolean;
}

export interface ProcessingStepViewer {
  id: string;
  viewType: "processingStep";
  documentFamilyId: string;
  title: string;
  isSidecar: boolean;
}

export interface ExecutionViewer {
  id: string;
  viewType: "execution";
  executionId: string;
  title: string;
  isSidecar: boolean;
}

export interface TextViewer {
  id: string;
  viewType: "text";
  language: string;
  text: string;
  title: string;
  isSidecar: boolean;
  resourceRef: string | undefined;
  executionId: string | undefined;
  objectType: string | undefined;
  objectRef: string | undefined;
}

export interface ActiveSelection {
  viewId: string;
  sidecar: boolean;
  showPopup: boolean;
  viewType: string;
}

interface CostViewer {
  viewType: "costView";
  id: string;
  executionId: string;
  documentFamilyId: string;
  title: string;
  modelInteractions: ModelInteraction[];
  totalCost: string;
}

export const useWorkspace = defineStore("workspace", () => {
  const availablePanels = ref([
    { name: "Assistants", id: "assistants" },
    { name: "Document Stores", id: "documentStores" },
    { name: "Data Forms", id: "dataForms" },
    { name: "Properties", id: "properties" },
    { name: "Exceptions", id: "exceptions" },
    { name: "Insights", id: "insights" },
    { name: "Data Definition", id: "taxonomies" },
    { name: "Chats", id: "channel" },
    { name: "Guidance", id: "guidance" },
    { name: "Navigation", id: "navigation" },
    { name: "Audit/Notes", id: "auditNotes" },
  ]);

  const currentWorkspaceId: Ref<string | undefined> = ref(undefined);
  const workspaceDirty: Ref<boolean> = ref(false);
  const currentWorkspace: Ref<Workspace | undefined> = ref(undefined);
  const isSidecar: Ref<boolean> = ref(false);
  const documentViews: Ref<DocumentViewer[]> = ref([]);
  const dataFormViews: Ref<DataFormViewer[]> = ref([]);
  const executionViews: Ref<ExecutionViewer[]> = ref([]);
  const processingStepViews: Ref<ProcessingStepViewer[]> = ref([]);
  const textViews: Ref<TextViewer[]> = ref([]);
  const documentFamilies = ref<Map<string, DocumentFamily>>(new Map());
  const dataObjects = ref<Map<string, DataObject>>(new Map());
  const activeView: Ref<DocumentViewer | DataFormViewer | ExecutionViewer | TextViewer | undefined> = ref();
  const transactionStart: Ref<number> = ref(Date.now());

  const focusedAttribute: Ref<DataAttribute | undefined> = ref();
  const focusedNodeUuid: Ref<string | undefined> = ref();
  const focusTagUuid: Ref<string | undefined> = ref();

  const activeChannelId: Ref<string | undefined> = ref();

  const sidecarPanelOpen = ref(false);

  const newLabels: Ref<string[]> = ref([]);
  const removedLabels: Ref<string[]> = ref([]);

  // Dirty data tracking
  const updatedDataExceptionsUuids: Ref<string[]> = ref([]);
  const updatedAttributeUuids: Ref<string[]> = ref([]);
  const newDataObjectUuids: Ref<string[]> = ref([]);

  const updatedDataObjectUuids: Ref<string[]> = ref([]);

  const deletedDataExceptionsIds: Ref<string[]> = ref([]);
  const deletedAttributeIds: Ref<string[]> = ref([]);
  const deletedDataObjectIds: Ref<string[]> = ref([]);
  const documentFamiliesWithTagChanges: Ref<Map<string, DocumentFeatureSetDTO>> = ref(new Map());

  const costViews = ref([] as CostViewer[]);

  const sidecarViews = computed(() => {
    return views.value.filter(view => view.isSidecar);
  });

  const documentFamiliesInWorkspaceChanged = ref(false);

  const { updatedTaxonomyRefs } = storeToRefs(appStore.projectStore);

  const isDirty = computed(() => {
    return (
      updatedDataExceptionsUuids.value.length > 0
      || updatedAttributeUuids.value.length > 0
      || newDataObjectUuids.value.length > 0
      || deletedDataExceptionsIds.value.length > 0
      || deletedAttributeIds.value.length > 0
      || deletedDataObjectIds.value.length > 0
      || documentFamiliesWithTagChanges.value.size > 0
      || updatedTaxonomyRefs.value.length > 0
      || documentFamiliesInWorkspaceChanged.value || workspaceDirty.value
    );
  });

  const { user } = storeToRefs(appStore.userStore);

  const activeSelectionView = ref<ActiveSelection | undefined>(undefined);

  const workspaceSidebar = computed(() => {
    const { documentStores } = storeToRefs(appStore.projectStore);

    const navigation = [] as { id: string; name: string; iconName: any; objectRef?: any }[];

    // We want to have a project documents section for each of the
    // stores - it'll be easier to work with I think
    if (currentWorkspace.value?.workspaceStorage?.availablePanels?.documentStores) {
      documentStores.value.forEach((documentStore: Store) => {
        navigation.push({
          id: `store-${documentStore.ref}`,
          name: `${documentStore.name}`,
          iconName: "projectDoc",
        });
      });
    }

    if (currentWorkspace.value?.workspaceStorage?.availablePanels?.channel) {
      navigation.push({
        id: "channel",
        name: "Chats",
        iconName: "assistant",
      });
    }

    if (currentWorkspace.value?.workspaceStorage?.availablePanels?.dataForms) {
      navigation.push({
        id: "dataForms",
        name: "Data Forms",
        iconName: "dataForm",
      });
    }

    if (currentWorkspace.value?.workspaceStorage?.availablePanels?.taxonomies) {
      navigation.push({
        id: "taxonomy",
        name: "Data Definition",
        iconName: "sitemap",
      });
    }

    if (activeView.value && currentWorkspace.value?.workspaceStorage?.availablePanels?.properties) {
      navigation.push(
        {
          id: "properties",
          name: "Properties",
          iconName: "properties",
        },
      );
    }

    if (activeView.value && currentWorkspace.value?.workspaceStorage?.availablePanels?.exceptions) {
      navigation.push({
        id: "exceptions",
        name: "Exceptions",
        iconName: "exceptions",
      });
    }

    if (activeView.value && currentWorkspace.value?.workspaceStorage?.availablePanels?.auditNotes) {
      navigation.push({
        id: "auditNotes",
        name: "Audit",
        iconName: "audit",
      });
    }

    if (activeView.value && currentWorkspace.value?.workspaceStorage?.availablePanels?.insights) {
      navigation.push({
        id: "insights",
        name: "Insights",
        iconName: "insights",
      });
    }

    if (activeView.value?.viewType === "dataForm" && currentWorkspace.value?.workspaceStorage?.availablePanels?.auditEvents) {
      navigation.push({
        id: "auditEvents",
        name: "Audit Trail",
        iconName: "auditTrail",
      });
    }

    if (activeView.value?.viewType === "document" && currentWorkspace.value?.workspaceStorage?.availablePanels?.navigation) {
      navigation.push(...[{
        id: "navigation",
        name: "Navigation",
        iconName: "navigation",
      },
      ]);
    }

    if (currentWorkspace.value?.workspaceStorage?.availablePanels?.guidance) {
      navigation.push(...[{
        id: "guidance",
        name: "Guidance",
        iconName: "guidance",
      },
      ]);
    }

    if (currentWorkspace.value?.workspaceStorage?.availablePanels?.assistants) {
      navigation.push(...[{
        id: "assistants",
        name: "Assistants",
        iconName: "assistants",
      },
      ]);
    }

    if (user.value?.showDeveloperTools) {
      navigation.push(...[{
        id: "workspaceConfig",
        name: "Workspace Configuration",
        iconName: "guidance",
      },
      ]);
    }

    if (appStore.platformStore.currentSidebar === "first" || appStore.platformStore.currentSidebar === "unset" || appStore.platformStore.currentSidebar === undefined) {
      appStore.platformStore.setCurrentSidebar(navigation[0].id);
    }

    return navigation;
  });

  const views = computed(() => {
    const allViews = [] as (DocumentViewer | DataFormViewer | ExecutionViewer | TextViewer | ProcessingStepViewer | CostViewer)[];
    if (documentViews.value) {
      allViews.push(...documentViews.value);
    }
    if (dataFormViews.value) {
      allViews.push(...dataFormViews.value);
    }
    if (executionViews.value) {
      allViews.push(...executionViews.value);
    }
    if (processingStepViews.value) {
      allViews.push(...processingStepViews.value);
    }
    if (textViews.value) {
      allViews.push(...textViews.value);
    }
    if (costViews.value) {
      allViews.push(...costViews.value);
    }
    return allViews.filter(view => !view.isSidecar);
  });

  function setActiveSelectionViewById(viewId: string) {
    const view = getViewById(viewId);
    setActiveSelectionView(view);
  }

  function setActiveView(view: DocumentViewer | DataFormViewer | ExecutionViewer | TextViewer | ProcessingStepViewer | CostViewer | undefined) {
    activeView.value = view;
    if (view?.viewType === "document") {
      setActiveSelectionView(view as DocumentViewer);
    }
  }

  function getViewMetadata(view: DocumentViewer | DataFormViewer | ExecutionViewer | TextViewer | ProcessingStepViewer | CostViewer): any {
    if (view.viewType === "document") {
      const documentFamily = documentFamilies.value.get(view.documentFamilyId);
      const store = getStoreByRef(documentFamily?.storeRef);
      return {
        documentFamily,
        store,
      };
    } else if (view.viewType === "dataForm") {
      const projectStore = useProject();
      const { allDataForms } = storeToRefs(projectStore);
      return { dataForm: allDataForms.value.find((df: DataForm) => df.ref === view.dataFormRef) };
    } else {
      return {};
    }
  }

  function getTitle(view: DataFormViewer | DocumentViewer | ExecutionViewer | TextViewer | ProcessingStepViewer | CostViewer): string {
    const projectStore = useProject();
    const { allDataForms } = storeToRefs(projectStore);
    if (view.viewType === "dataForm") {
      const formName = allDataForms.value.find((df: DataForm) => df.ref === view.dataFormRef)?.name;

      // We need to look up the data view store to get the name of the document family
      const dataViewStore = createDataFormViewerStore(view.id);
      const { documentFamilyIds } = storeToRefs(dataViewStore);
      if (documentFamilyIds.value.length > 0) {
        const documentFamily = documentFamilies.value.get(documentFamilyIds.value[0]);
        if (documentFamily) {
          return `${formName || "Untitled"} - ${documentFamily.path}`;
        }
      }
      return (formName || "Loading...") as string;
    } else if (view.viewType === "document") {
      const documentFamily = documentFamilies.value.get(view.documentFamilyId);

      if (documentFamily) {
        return view.executionId ? `${documentFamily.path} (Test Result)` : documentFamily.path;
      } else {
        return "Loading...";
      }
    } else if (view.viewType === "execution") {
      return view.title;
    } else if (view.viewType === "text") {
      return view.title;
    } else if (view.viewType === "processingStep") {
      return view.title;
    } else if (view.viewType === "costView") {
      return view.title;
    } else {
      return "Loading...";
    }
  }

  function hasDocumentFamilyById(documentFamilyId: string): boolean {
    return documentFamilies.value.has(documentFamilyId);
  }

  async function getDocumentFamilyById(id: string, force = false): Promise<undefined | DocumentFamily> {
    const existingDocumentFamily = documentFamilies.value.get(id);
    if (existingDocumentFamily !== undefined && !force) {
      return existingDocumentFamily;
    }

    try {
      const documentFamily = await getDocumentFamily(id) as DocumentFamily;
      documentFamilies.value.set(id, documentFamily);

      return documentFamilies.value.get(id);
    } catch (e) {
      log.warn("Error getting document family");
      return undefined;
    }
  }

  function gotoTagInstance(tagInstance: TagInstance, viewId: string) {
    documentViews.value.forEach((documentView) => {
      if (documentView.id === viewId) {
        // TODO implment me
      }
    });
  }

  async function removeViewById(viewId: string) {
    if (dataFormViews) {
      dataFormViews.value = dataFormViews.value.filter((dataFormView: DataFormViewer) => {
        return dataFormView.id !== viewId;
      });
    }

    if (documentViews.value) {
      documentViews.value = documentViews.value.filter((documentView: DocumentViewer) => {
        return documentView.id !== viewId;
      });
    }

    if (executionViews.value) {
      executionViews.value = executionViews.value.filter((executionView: ExecutionViewer) => {
        return executionView.id !== viewId;
      });
    }

    if (textViews.value) {
      textViews.value = textViews.value.filter((textView: TextViewer) => {
        return textView.id !== viewId;
      });
    }

    if (processingStepViews.value) {
      processingStepViews.value = processingStepViews.value.filter((processingStepView: ProcessingStepViewer) => {
        return processingStepView.id !== viewId;
      });
    }

    if (costViews.value) {
      costViews.value = costViews.value.filter((costView: CostViewer) => {
        return costView.id !== viewId;
      });
    }

    if (activeView.value && activeView.value.id === viewId) {
      if (views.value.length > 0) {
        setActiveView(views.value[0]);
      } else {
        setActiveView(undefined);
      }
    }
  }

  function getViewById(viewId: string, includeSidecar = true): DocumentViewer | DataFormViewer | ExecutionViewer | TextViewer | ProcessingStepViewer | CostViewer | undefined {
    if (dataFormViews.value) {
      for (const dataFormView of dataFormViews.value) {
        if (dataFormView.id === viewId && (includeSidecar || !dataFormView.isSidecar)) {
          return dataFormView;
        }
      }
    }

    if (documentViews.value) {
      for (const documentView of documentViews.value) {
        if (documentView.id === viewId && (includeSidecar || !documentView.isSidecar)) {
          return documentView;
        }
      }
    }

    if (executionViews.value) {
      for (const executionView of executionViews.value) {
        if (executionView.id === viewId) {
          return executionView;
        }
      }
    }

    if (textViews.value) {
      for (const textView of textViews.value) {
        if (textView.id === viewId) {
          return textView;
        }
      }
    }

    if (processingStepViews.value) {
      for (const processingStepView of processingStepViews.value) {
        if (processingStepView.id === viewId) {
          return processingStepView;
        }
      }
    }

    if (costViews.value) {
      for (const costView of costViews.value) {
        if (costView.id === viewId) {
          return costView;
        }
      }
    }

    return undefined;
  }

  function buildDataFormState(dataFormView: DataFormViewer): DataFormState {
    const dataFormState = {} as DataFormState;
    dataFormState.dataFormRef = dataFormView.dataFormRef;
    dataFormState.documentFamilyIds = dataFormView.documentFamilyIds;
    dataFormState.id = dataFormView.id;
    return dataFormState;
  }

  function buildDocumentViewState(documentView: DocumentViewer): DocumentViewState {
    const documentViewState = {} as DocumentViewState;
    documentViewState.documentFamilyId = documentView.documentFamilyId;
    documentViewState.id = documentView.id;
    return documentViewState;
  }

  async function saveWorkspace() {
    if (currentWorkspace.value == null) {
      return;
    }

    const loadingEvent = {
      id: currentWorkspace.value.id,
      title: "Saving Workspace",
      subtitle: "",
      progress: undefined,
      progressMax: undefined,
    } as LoadingEvent;
    appStore.platformStore.addLoadingEvent(loadingEvent);

    const workspaceStorage = currentWorkspace.value.workspaceStorage as WorkspaceStorage;
    workspaceStorage.dataFormStates = [];

    if (dataFormViews.value) {
      for (const dataFormView of dataFormViews.value) {
        if (!dataFormView.ephemeral) {
          workspaceStorage.dataFormStates?.push(buildDataFormState(dataFormView));
        }
      }
    }

    if (documentViews.value) {
      workspaceStorage.documentViewStates = [];
      for (const documentView of documentViews.value) {
        if (!documentView.ephemeral) {
          workspaceStorage.documentViewStates?.push(buildDocumentViewState(documentView));
        }
      }
    }

    if (currentWorkspace.value) {
      const workspace = {} as Workspace;
      workspace.workspaceStorage = workspaceStorage;
      workspace.id = currentWorkspace.value.id;
      workspace.uuid = currentWorkspace.value.uuid;
      workspace.changeSequence = currentWorkspace.value.changeSequence;
      workspace.name = "Workspace";
      const projectStore = useProject();
      const { project } = storeToRefs(projectStore);
      if (!project.value) {
        appStore.platformStore.removeLoadingEvent(loadingEvent);
        throw new Error("Project not found");
      }
      workspace.project = {
        id: project.value.id,
        name: project.value.name,
      };
      try {
        notify({
          group: "generic",
          title: "Updating workspace",
        }, 500);
        currentWorkspace.value = await updateWorkspace(currentWorkspace.value.id as string, workspace);
        workspaceDirty.value = false;
      } catch (e) {
        await handleError(e);
      }
    } else {
      log.info("No workspace id found, not saving workspace");
    }
    appStore.platformStore.removeLoadingEvent(loadingEvent);
  }

  async function addView(view: DocumentViewer | DataFormViewer | ExecutionViewer | TextViewer | ProcessingStepViewer | CostViewer) {
    if (view.viewType === "dataForm") {
      if (view.documentFamilyIds) {
        for (const documentFamilyId of view.documentFamilyIds) {
          if (!documentFamilies.value.has(documentFamilyId)) {
            try {
              const documentFamily = await getDocumentFamily(documentFamilyId);
              if (documentFamily.id) {
                documentFamilies.value.set(documentFamily.id, documentFamily);
              }
            } catch (error) {
              log.warn("Error getting document family");
            }
          }
        }
      }

      dataFormViews.value = dataFormViews.value.filter((dataFormView: DataFormViewer) => {
        return dataFormView.id !== view.id;
      });

      dataFormViews.value.push(view);
    }

    if (view.viewType === "execution") {
      executionViews.value = executionViews.value.filter((executionView: ExecutionViewer) => {
        return executionView.id !== view.id;
      });

      executionViews.value.push(view);
    }

    if (view.viewType === "document") {
      if (view.documentFamilyId) {
        const [documentFamily] = await Promise.all([getDocumentFamily(view.documentFamilyId)]);
        if (documentFamily.id) {
          documentFamilies.value.set(documentFamily.id, documentFamily);

          documentViews.value = documentViews.value.filter((documentView: DocumentViewer) => {
            return documentView.id !== view.id;
          });

          documentViews.value.push(view);
        }
      }
    }

    if (view.viewType === "text") {

      textViews.value = textViews.value.filter((textViewer: TextViewer) => {
        return textViewer.id !== view.id;
      });

      textViews.value.push(view);
    }

    if (view.viewType === "processingStep") {

      processingStepViews.value = processingStepViews.value.filter((processingStepView: ProcessingStepViewer) => {
        return processingStepView.id !== view.id;
      });

      processingStepViews.value.push(view);
    }

    if (view.viewType === "costView") {
      costViews.value = costViews.value.filter((costView: CostViewer) => costView.id !== view.id);
      costViews.value.push(view);
    }

    if (!view.isSidecar) {
      setActiveView(view);
    }
  }

  async function loadWorkspace(workspaceId: string) {
    // We need to clear all the state information

    if (currentWorkspaceId.value === workspaceId) {
      log.info("Already loaded workspace");
      return;
    }

    await clearCurrentWorkspace();

    const workspace = await getWorkspace(workspaceId);
    if (workspace.workspaceStorage === undefined) {
      workspace.workspaceStorage = {} as WorkspaceStorage;
    }

    if (workspace.workspaceStorage?.defaultSidebar) {
      if (workspace.workspaceStorage?.defaultSidebar === "first") {
        // We will set this later
        appStore.platformStore.setCurrentSidebar("first");
      } else {
        appStore.platformStore.setCurrentSidebar(workspace.workspaceStorage.defaultSidebar);
      }
    } else {
      appStore.platformStore.setCurrentSidebar("unset");
    }

    if (!workspace.workspaceStorage?.availablePanels) {
      if (!workspace.workspaceStorage) {
        workspace.workspaceStorage = {} as WorkspaceStorage;
      }

      workspace.workspaceStorage.availablePanels = {};
      availablePanels.value.forEach((panel) => {
        workspace.workspaceStorage.availablePanels[panel.id] = true;
      });
    }

    const workspaceStorage = workspace.workspaceStorage;
    currentWorkspaceId.value = workspaceId;
    currentWorkspace.value = workspace;

    // We will initialize the workspace document families from the server
    const workspaceDocumentFamilies = await listWorkspaceDocumentFamilies(workspaceId);
    documentFamilies.value.clear();
    for (const workspaceDocumentFamily of workspaceDocumentFamilies.content ? workspaceDocumentFamilies.content : []) {
      if (workspaceDocumentFamily.id) {
        documentFamilies.value.set(workspaceDocumentFamily.id, workspaceDocumentFamily);
      }
    }

    documentViews.value = [];
    documentFamilies.value.clear();
    if (workspaceStorage.documentViewStates) {
      for (const documentViewState of workspaceStorage.documentViewStates) {
        try {
          if (!documentFamilies.value.has(documentViewState.documentFamilyId as string)) {
            const documentFamily = await getDocumentFamily(documentViewState.documentFamilyId as string);
            documentFamilies.value.set(documentFamily.id as string, documentFamily);
          }
          await openDocumentView(documentFamilies.value.get(documentViewState.documentFamilyId) as DocumentFamily, false);
        } catch (e) {
          log.warn("Error loading document view state");
        }
      }
    }

    dataFormViews.value = [];
    if (workspaceStorage.dataFormStates) {
      for (const dataFormState of workspaceStorage.dataFormStates) {
        const newDataFormView = {
          viewType: "dataForm",
          id: dataFormState.id,
          dataFormRef: dataFormState.dataFormRef,
          documentFamilyIds: dataFormState.documentFamilyIds,
        } as DataFormViewer;
        await addView(newDataFormView);
      }
    }

    if (documentViews.value.length > 0) {
      setActiveView(documentViews.value[0]);
    } else if (dataFormViews.value.length > 0) {
      setActiveView(dataFormViews.value[0]);
    }

    // We need to work out if there are document families that are already in the workspace
    listWorkspaceDocumentFamilies(workspaceId, { pageSize: 99 }).then(async (workspaceDocumentFamilies) => {
      if (workspaceDocumentFamilies.content) {
        for (const workspaceDocumentFamily of workspaceDocumentFamilies.content) {
          await addDocumentFamily(workspaceDocumentFamily, false, false, false);
        }
      }
    });

    // Start the transaction to capture analytics
    startTransaction();
  }

  function removeDataForm(dataFormView: DataFormViewer) {
    dataFormViews.value = dataFormViews.value.filter((view: DataFormViewer) => view.id !== dataFormView.id);
    if (activeView.value?.id === dataFormView.id) {
      // if we have any other data forms open, we will set the active view to the first one
      if (dataFormViews.value.length > 0) {
        setActiveView(dataFormViews.value[0]);
      } else {
        setActiveView(undefined);
      }
    }
  }

  function clearDataForm() {
    dataFormViews.value = [];
    setActiveView(undefined);
  }
  function addDataForm(dataForm: DataForm, documentFamily: DocumentFamily | undefined = undefined, ephemeral = false): DataFormViewer | undefined {
    if (documentFamily && documentFamily.id) {
      if (!documentFamilies.value.has(documentFamily.id)) {
        documentFamilies.value.set(documentFamily.id, documentFamily);
        getDataObjectsForDocumentFamilyId(documentFamily.id);
      }

      const dataFormView = {
        id: uuidv4(),
        viewType: "dataForm",
        dataFormRef: dataForm.ref,
        documentFamilyIds: [documentFamily.id],
        ephemeral,
      } as DataFormViewer;
      dataFormViews.value.push(dataFormView);
      setActiveView(dataFormView);
      return dataFormView;
    } else {
      if (!dataFormViews.value.find((dataFormView: DataFormViewer) => dataFormView.dataFormRef === dataForm.ref)) {
        log.info("Creating data form view");
        const dataFormView = {
          id: uuidv4(),
          viewType: "dataForm",
          dataFormRef: dataForm.ref,
          ephemeral,
        } as DataFormViewer;
        dataFormViews.value.push(dataFormView);
        setActiveView(dataFormView);
        return dataFormView;
      } else {
        log.warn("Data form already open");
        setActiveView(dataFormViews.value.find((dataFormView: DataFormViewer) => dataFormView.dataFormRef === dataForm.ref));
      }
    }

    return undefined;
  }

  async function createTextViewer(title: string, text: string | undefined, executionId: string | undefined, language: string | undefined, resourceRef: string | undefined): Promise<TextViewer> {
    const textView = {
      id: uuidv4(),
      viewType: "text",
      text,
      title,
      isSidecar: false,
      ephemeral: true,
      executionId,
      objectType: undefined,
      objectRef: undefined,
      language,
      resourceRef,
    } as TextViewer;
    await addView(textView);
    setActiveView(textView);
    return textView;
  }

  async function addDocumentFamily(documentFamily: DocumentFamily, ephemeral = false, addView = true, addToWorkspace = true) {
    if (documentFamily.id && currentWorkspaceId.value) {
      if (!documentFamilies.value.has(documentFamily.id)) {
        documentFamilies.value.set(documentFamily.id, documentFamily);
        getDataObjectsForDocumentFamilyId(documentFamily.id);
        await addDocumentFamilyToWorkspace(currentWorkspaceId.value, documentFamily);
      }

      if (addView) {
        const documentView = {
          viewType: "document",
          documentFamilyId: documentFamily.id,
          id: uuidv4(),
          ephemeral,
          currentPage: 0,
          isSidecar: false,
        } as DocumentViewer;
        documentViews.value.push(documentView);
        setActiveView(documentView);

        if (addToWorkspace && !ephemeral) {
          documentFamiliesInWorkspaceChanged.value = true;
        }
        return documentView;
      }
    } else {
      throw new Error("Document family has no id, or we don't have a workspace ID");
    }
  }

  function isDocumentViewOpen(documentFamily: DocumentFamily): boolean {
    return documentViews.value.some((documentView: DocumentViewer) => {
      return documentView.documentFamilyId === documentFamily.id;
    });
  }

  function openDataForm(dataForm: DataForm, documentFamily: DocumentFamily, ephemeral = false) {
    const existingDataFormView = dataFormViews.value.find((dataFormView: DataFormViewer) => {
      return dataFormView.dataFormRef === dataForm.ref;
    });

    log.info(`Existing data form ${existingDataFormView}`);

    if (existingDataFormView) {
      setActiveView(existingDataFormView);
      return;
    }

    const dataFormView = {
      documentFamilyIds: [documentFamily.id],
      dataFormRef: dataForm.ref,
      id: uuidv4(),
      viewType: "dataForm",
      ephemeral,
    } as DataFormViewer;

    dataFormViews.value.push(dataFormView);

    if (documentFamily.id) {
      getDataObjectsForDocumentFamilyId(documentFamily.id);
    }

    setActiveView(dataFormView);
  }

  function isSidecarOpen(): boolean {
    return sidecarPanelOpen.value;
  }

  async function openDocumentView(documentFamily: DocumentFamily, ephemeral = false) {
    if (documentFamily.id) {
      const existingDocumentView = documentViews.value.find((documentView: DocumentViewer) => {
        return documentView.documentFamilyId === documentFamily.id;
      });

      if (existingDocumentView) {
        if (!existingDocumentView.isSidecar) {
          setActiveView(existingDocumentView);
          return;
        } else {
          existingDocumentView.isSidecar = false;

          // Replace existing document view in the documentViews
          documentViews.value = documentViews.value.filter((documentView: DocumentViewer) => {
            return documentView.documentFamilyId !== documentFamily.id;
          });
          documentViews.value.push(existingDocumentView);

          createDocumentViewerStore(existingDocumentView.id)?.setSidecar(false);

          sidecarPanelOpen.value = false;
          const useSidecar = createSidecar(currentWorkspaceId.value as string);
          useSidecar.clearSidecarView();
          setActiveView(existingDocumentView);
          return;
        }
      }

      const documentView = {
        documentFamilyId: documentFamily.id,
        id: documentFamily.id,
        viewType: "document",
        ephemeral,
        currentPage: 0,
        isSidecar: false,
      } as DocumentViewer;
      documentViews.value.push(documentView);
      getDataObjectsForDocumentFamilyId(documentFamily.id);
      await addDocumentFamily(documentFamily, ephemeral, false, false);
      setActiveView(documentView);
    }
  }

  async function removeDocumentFamily(documentFamily: DocumentFamily) {
    if (documentFamily.id && currentWorkspaceId.value) {
      if (documentFamilies.value.has(documentFamily.id)) {
        try {
          await removeDocumentFamilyFromWorkspace(currentWorkspaceId.value, documentFamily.id);
        } catch {
          log.error("Error removing document family from workspace");
        }

        documentFamilies.value.delete(documentFamily.id);

        // We need to make sure we remove all the data objects as well
        for (const dataObject of dataObjects.value.values()) {
          if (dataObject.documentFamily && dataObject.documentFamily.id === documentFamily.id) {
            dataObjects.value.delete(dataObject.uuid as string);
          }
        }
      }
      documentViews.value = documentViews.value.filter((documentView: DocumentViewer) => {
        return documentView.documentFamilyId !== documentFamily.id;
      });

      documentFamiliesInWorkspaceChanged.value = true;
      documentFamiliesWithTagChanges.value.delete(documentFamily.id);
    }
  }

  function getDataObjectsForDocumentFamilyId(id: string): void {
    // Function to handle pagination and data fetching
    const fetchPage = (pageNumber: number = 1) => {
      listDataObjects({
        filter: `documentFamily.id: '${id}'`,
        pageSize: 60,
        page: pageNumber,
      }).then((pageDataObjects) => {
        // Load new data objects into the workspace
        pageDataObjects.content?.forEach((dataObject) => {
          if (dataObject.uuid && !dataObjects.value.has(dataObject.uuid)) {
            dataObjects.value.set(dataObject.uuid, dataObject);
          }
        });
        // Check if there are more pages to fetch
        if (pageNumber < Math.ceil(pageDataObjects.totalElements / 10) - 1) {
          fetchPage(pageNumber + 1); // Fetch the next page
        }
      }).catch((err) => {
        handleError(err);
      });
    };

    // We need to remove the data objects related to this document, so we can reload them
    for (const dataObject of dataObjects.value.values()) {
      if (dataObject.documentFamily.id === id && dataObject.uuid) {
        dataObjects.value.delete(dataObject.uuid);
      }
    }

    // Start fetching from the first page
    fetchPage();
  }

  async function createNewWorkspace(): Promise<string> {
    log.info("Creating new workspace");
    const projectStore = useProject();
    const { project } = storeToRefs(projectStore);
    if (!project.value) {
      throw new Error("No project is selected");
    } else {
      let newWorkspace: any = {};
      newWorkspace.name = "New Workspace";
      newWorkspace.workspaceStorage = {} as WorkspaceStorage;
      newWorkspace.workspaceStorage.availablePanels = {
        documentStores: true,
        dataForms: true,
        properties: true,
        exceptions: true,
        taxonomies: true,
        navigation: true,
        assistants: true,
      };
      log.info(`Creating new workspace for project: ${project.value.name}`);
      newWorkspace.project = toRaw(project.value);
      newWorkspace = await createWorkspace(newWorkspace);
      return newWorkspace.id as string;
    }
  }

  async function openWorkspaceForDocumentFamily(documentFamily: DocumentFamily) {
    // If we have any workspaces - open the first one and use that
    // otherwise we will need to create a new workspace
    const project = appStore.projectStore.project;
    const workspaces = await listWorkspaces({ pageSize: 1, filter: `project.id: '${project.id}'` });
    if (workspaces.totalElements && workspaces.totalElements > 0 && workspaces.content) {
      await openWorkspace(workspaces.content[0]);
      await addDocumentFamily(documentFamily);
      router.push({ path: `/a/o/${project.organization.id}/p/${project.id}/workspaces/${workspaces.content[0].id}` }).then(() => {
      });
    } else {
      const newWorkspaceId = await createNewWorkspace();
      await loadWorkspace(newWorkspaceId);
      await addDocumentFamily(documentFamily);
      router.push({ path: `/a/o/${project.organization.id}/p/${project.id}/workspaces/${newWorkspaceId}` }).then(() => {
      });
    }
  }

  async function openWorkspace(workspace: Workspace) {
    if (workspace.id != null) {
      const loadingEvent = {
        id: "workspace",
        title: "Loading Workspace",
        subtitle: "",
        progress: undefined,
        progressMax: undefined,
      } as LoadingEvent;
      appStore.platformStore.addLoadingEvent(loadingEvent);
      await loadWorkspace(workspace.id);
      appStore.platformStore.removeLoadingEvent(loadingEvent);
      router.push({ path: `workspaces/${workspace.id}` }).then(() => {
      });
    }
  }

  function clearWorkspaceInstances() {
    log.info("Clearing workspace instances");
    newLabels.value = [];
    removedLabels.value = [];
    updatedAttributeUuids.value = [];
    updatedDataExceptionsUuids.value = [];
    deletedDataExceptionsIds.value = [];
    deletedAttributeIds.value = [];
    newDataObjectUuids.value = [];
    deletedDataObjectIds.value = [];
    activeSelectionView.value = undefined;
  }

  async function clearCurrentWorkspace(force = false, notes = "Do you want to discard the changes, or stay in the workspace to save them?", cancelText="Stay in Workspace") {
    if (isDirty.value && !force) {
      const confirmLostChanges = createConfirmDialog(KodexaConfirm);
      const result = await confirmLostChanges.reveal({
        icon: "alert-circle-outline",
        title: "Unsaved Changes",
        message: "You have unsaved changes, if you continue they will be lost!",
        notes,
        confirmText: "Discard Changes",
        confirmIcon: "delete",
        cancelText,
        cancelIcon: "close",
        type: "danger",
      });

      if (result.isCanceled) {
        return false;
      }
    }

    log.info("Clearing workspace");
    clearWorkspaceInstances();

    if (currentWorkspaceId.value) {
      log.info("Disposing of sidecar");
      const sidecarStore = createSidecar(currentWorkspaceId.value);
      sidecarStore.clearSidecarView();
    }

    currentWorkspaceId.value = undefined;
    currentWorkspace.value = undefined;
    workspaceDirty.value = false;
    documentViews.value = [];
    documentFamilies.value.clear();
    dataFormViews.value = [];
    executionViews.value = [];
    textViews.value = [];
    processingStepViews.value = [];
    dataObjects.value.clear();
    setActiveView(undefined);

    // We need to clear all the dirty tracking stuff
    updatedAttributeUuids.value = [];
    updatedDataExceptionsUuids.value = [];
    updatedDataObjectUuids.value = [];
    deletedDataExceptionsIds.value = [];
    deletedAttributeIds.value = [];
    newDataObjectUuids.value = [];
    deletedDataObjectIds.value = [];
    updatedTaxonomyRefs.value = [];
    documentFamiliesWithTagChanges.value.clear();
    documentFamiliesInWorkspaceChanged.value = false;

    return true;
  }

  async function deleteWorkspaceById(workspaceId: string) {
    await deleteWorkspace(workspaceId);
  }

  function addDataObject(dataObject: DataObject) {
    dataObjects.value.set(dataObject.uuid as string, toRaw(dataObject));
    addNewDataObjectIds(dataObject, dataObject.uuid as string);
    log.info(`Added data object, now we have ${dataObjects.value.size} data objects`);
  }

  function updateDataObject(dataObject: DataObject, partialDataObject: Partial<DataObject>) {
    const realDataObject = dataObjects.value.get(dataObject.uuid as string);
    if (!realDataObject) {
      return;
    }

    Object.assign(realDataObject, partialDataObject);
    addUpdatedDataObjectIds(realDataObject);
  }

  function addUpdatedDataObjectIds(realDataObject: DataObject) {
    if (!realDataObject.id || updatedDataObjectUuids.value.includes(realDataObject.id)) {
      return;
    }

    updatedDataObjectUuids.value.push(realDataObject.uuid as string);
  }

  /**
   * Finds a data attribute by its attribute ID.
   *
   * @param {string} attributeId - The ID of the attribute to find.
   * @returns {DataAttribute | undefined} The found attribute, or undefined if not found.
   */
  function findAttribute(attributeId: string): DataAttribute | undefined {
    for (const dataObject of dataObjects.value.values()) {
      if (dataObject.attributes) {
        for (const attribute of dataObject.attributes) {
          if (attribute.id === attributeId) {
            return attribute;
          }
        }
      }
    }
  }

  /**
   * Finds a DataAttribute by its UUID.
   *
   * @param {string} attributeUuid - The UUID of the attribute to find.
   * @returns {DataAttribute | undefined} - The found DataAttribute or undefined if not found.
   */
  function findAttributeByUuid(attributeUuid: string): DataAttribute | undefined {
    for (const dataObject of dataObjects.value.values()) {
      if (dataObject.attributes) {
        for (const attribute of dataObject.attributes) {
          if (attribute.uuid === attributeUuid) {
            return attribute;
          }
        }
      }
    }
  }

  /**
   * Find a DataObject by its attribute UUID.
   *
   * @param {string} attributeUuid - The UUID of the attribute.
   * @returns {DataObject | undefined} - The found DataObject, or undefined if not found.
   */
  function findDataObjectByAttributeUuid(attributeUuid: string): DataObject | undefined {
    for (const dataObject of dataObjects.value.values()) {
      if (dataObject.attributes) {
        for (const attribute of dataObject.attributes) {
          if (attribute.uuid === attributeUuid) {
            return dataObject;
          }
        }
      }
    }
  }

  /**
   * Searches for a data object based on its UUID.
   *
   * @param {string} dataObjectUuid - The UUID of the data object to search for.
   * @return {DataObject|undefined} - The found data object, or undefined if not found.
   */
  function findDataObject(dataObjectUuid: string): DataObject | undefined {
    return dataObjects.value.get(dataObjectUuid);
  }

  /**
   * Retrieves the updated data exceptions based on the provided UUIDs.
   *
   * @returns {DataExceptionDTO[]} An array of DataExceptionDTO objects representing the updated data exceptions.
   */
  function getUpdatedDataExceptions(): DataExceptionDTO[] {
    const updatedDataExceptions: DataExceptionDTO[] = [];
    for (const dataExceptionUuid of updatedDataExceptionsUuids.value) {
      for (const dataObject of dataObjects.value.values()) {
        if (dataObject.dataExceptions) {
          for (const dataException of dataObject.dataExceptions) {
            if (dataException.uuid === dataExceptionUuid) {
              updatedDataExceptions.push(dataException);
              break;
            }
          }
        }
        if (dataObject.dataExceptions && dataObject?.attributes) {
          for (const dataAttribute of dataObject?.attributes) {
            if (dataAttribute.dataExceptions) {
              for (const dataException of dataAttribute.dataExceptions) {
                if (dataException.uuid === dataExceptionUuid) {
                  updatedDataExceptions.push(dataException);
                  break;
                }
              }
            }
          }
        }
      }
    }

    return updatedDataExceptions;
  }

  /**
   * Retrieves the latest content object from a given document family.
   *
   * @param {string} documentFamilyId - The ID of the document family.
   * @returns {Promise<ContentObject|undefined>} - The latest content object, or undefined if none exists.
   */
  async function getLatestContentObject(documentFamilyId: string): Promise<ContentObject | undefined> {
    // If I already have the document family use that
    const documentFamily = documentFamilies.value.get(documentFamilyId) || await getDocumentFamilyById(documentFamilyId);
    if (documentFamily) {
      const contentObjects = documentFamily.contentObjects;
      if (contentObjects && contentObjects.length > 0) {
        return (contentObjects[contentObjects.length - 1]) as ContentObject;
      }
    }
  }

  /**
   * Deletes a data object by its UUID.
   *
   * @param {string} dataObjectUuid - The UUID of the data object to delete.
   *
   * @return {void}
   */
  async function deleteDataObjectByUuid(dataObjectUuid: string): void {
    log.info(`Deleting data object ${dataObjectUuid}`);
    const dataObject = dataObjects.value.get(dataObjectUuid);

    if (!dataObject) {
      return;
    }

    const documentView = documentViews.value.find((documentView: DocumentViewer) => {
      return documentView.documentFamilyId === dataObject.documentFamily?.id;
    });

    // We need to now go through and work how to remove all the tags for all the attributes
    // from this data object
    const useDocumentView = createDocumentViewerStore(documentView?.id as string);

    if (!useDocumentView) {
      throw new Error("Document view not found");
    }

    dataObject.attributes?.forEach(async (attribute: DataAttribute) => {
      const attributeTag = { uuid: attribute.tagUuid, path: attribute.path, name: attribute.tag } as SelectedTag;
      await useDocumentView.removeTag(attributeTag);
    });

    const childDataObjectUuids = findChildDataObjectUuids(dataObject);
    for (const childDataObjectUuid of childDataObjectUuids) {
      const childDataObject = dataObjects.value.get(childDataObjectUuid);
      if (!childDataObject) {
        return;
      }

      if (childDataObject.id) {
        deletedDataObjectIds.value.push(childDataObject.id);
      } else {
        newDataObjectUuids.value = newDataObjectUuids.value.filter(uuid => uuid !== childDataObject.uuid);
      }

      dataObjects.value.delete(childDataObjectUuid);
      childDataObject.attributes?.forEach(async (attribute: DataAttribute) => {
        const attributeTag = { uuid: attribute.tagUuid, path: attribute.path, name: attribute.tag } as SelectedTag;
        await useDocumentView.removeTag(attributeTag);
      });
    }
  }

  /**
   * Deletes a data attribute from all data objects based on its UUID.
   *
   * @param {string} dataAttributeUuid - The UUID of the data attribute to be deleted.
   *
   * @return {void}
   */
  function deleteDataAttributeByUuid(dataAttributeUuid: string): void {
    // Should be string of Ids
    log.info(`Deleting data attribute ${dataAttributeUuid} from all data objects`);
    for (const dataObject of dataObjects.value.values()) {
      if (dataObject.attributes) {
        // We need to find all the attribute UUIDs that we are removing
        const attributeIds = dataObject.attributes.filter((attribute: DataAttribute) => attribute.uuid === dataAttributeUuid).filter((attribute: DataAttribute) => attribute.id).map((attribute: DataAttribute) => attribute.id as string);
        deletedAttributeIds.value.push(...attributeIds);
        dataObject.attributes = dataObject.attributes.filter((attribute: DataAttribute) => attribute.uuid !== dataAttributeUuid);
      }
    }
  }

  function findParentDataObject(dataObject: DataObject): DataObject | undefined {
    return dataObject.parentId ? Array.from(dataObjects.value.values()).find(dato => dato.id === dataObject.parentId) : Array.from(dataObjects.value.values()).find(dato => dato.uuid === dataObject?.parent?.uuid);
  }

  /**
   * Finds the Uuids of all child data objects belonging to a given deleted data object.
   *
   * @param {DataObject} deletedDataObject - The deleted data object to find child data objects for.
   * @return {string[]} - An array of Uuids of child data objects.
   */
  function findChildDataObjectUuids(deletedDataObject: DataObject) {
    const childDataObjectUuids: string[] = [deletedDataObject.uuid as string];
    for (const dataObject of dataObjects.value.values()) {
      if (!dataObject) {
        continue;
      }
      if (dataObject.parent?.uuid === deletedDataObject.uuid || childDataObjectUuids.includes(dataObject.parent?.uuid as string)) {
        childDataObjectUuids.push(dataObject.uuid as string);
      }
    }
    return childDataObjectUuids;
  }

  /**
   * Finds child data objects based on the provided parent data object.
   *
   * @param {DataObject} parentDataObject - The parent data object.
   * @return {Array<DataObject>} An array of child data objects.
   */
  function findChildDataObject(parentDataObject: DataObject) {
    const childDataObjects = [];
    for (const dataObject of dataObjects.value.values()) {
      if (dataObject?.parent?.uuid === parentDataObject.uuid) {
        childDataObjects.push(dataObject);
      }
    }
    return childDataObjects;
  }

  /**
   * Create a new attribute from a tag instance, we need to ensure that we manage the
   * type handling from the tag to the attribute.
   *
   * @param {TagInstance} tag
   * @param {DataObject} dataObject
   * @returns {DataAttribute}
   */
  async function createAttributeFromTag(tag: TagInstance, dataObject: DataObject): Promise<DataAttribute> {
    const newAttribute: DataAttribute = {
      tagUuid: tag.uuid,
      uuid: uuidv4(),
      value: tag.value,
      tag: tag.taxon.name,
      path: tag.taxon.path,
      typeAtCreation: tag.taxon.taxonType,
      confidence: 1,
      dataObject: { uuid: dataObject.uuid, id: dataObject.id },
      dataExceptions: [],
      dataFeatures: {
        page_number: tag.pageNumber,
        line_number: tag.lineNumber,
        group_uuid: tag.groupUuid,
        cell_index: tag.cellIndex,
      },
    };

    const dataAttributeNormalize = {
      dataAttribute: newAttribute,
      taxon: tag.taxon,
    } as DataAttributeNormalize;
    const dataAttribute = await normalize(dataAttributeNormalize);
    if (!dataObject.attributes) {
      dataObject.attributes = [];
    }

    dataObject.attributes.push(dataAttribute);

    if (tag.taxon.options && tag.taxon.options.length > 0) {
      const propertiesDialog = createConfirmDialog(KodexaAttributeProperties);
      await propertiesDialog.reveal({
        dataAttribute,
        modelValue: true,
      });
    }

    updatedAttributeUuids.value.push(dataAttribute.uuid as string);
    return newAttribute;
  }

  /**
   * Adds a label to the newLabels array.
   *
   * @param {string} label - The label to be added.
   *
   * @return {void} - This method does not return anything.
   */
  function addLabel(label: string): void {
    newLabels.value.push(label);
  }

  /**
   * Removes a label from document families and new labels
   *
   * @param {string} label - The label to remove
   *
   * @returns {void}
   */
  function removeLabel(label: string) {
    for (const docFam of documentFamilies.value.values()) {
      if (!docFam.labels) {
        return;
      }
      docFam.labels = docFam.labels.filter(docLabel => docLabel.name !== label);
    }
    newLabels.value.filter(newLabel => newLabel !== label);
    removedLabels.value.push(label);
  }

  /**
   * If we have added a tag then we need to make sure that we identify if we need to create a data attribute to capture it.
   *
   * @param {TagInstance} tag
   * @param {DocumentFamily} documentFamily
   * @param {FeatureSet} featureSet
   */
  async function tagAdded(tag: TagInstance, documentFamily: DocumentFamily, featureSet: DocumentFeatureSet): Promise<void> {
    log.info("Tag added");
    if (documentFamily.id && featureSet) {
      documentFamiliesWithTagChanges.value.set(documentFamily.id as string, featureSet);
    }

    const tagPath = tag.taxon.path;
    const parentPath = tagPath?.substr(0, tagPath?.lastIndexOf("/"));
    log.info(`Checking for tag ${tagPath} in parent ${parentPath}`);

    if (isSidecar.value && currentWorkspaceId.value) {
      // We need to inform that main workspace that a new tag has been added
      const useSidecar = createSidecar(currentWorkspaceId.value, true);
      log.info("Informing main workspace of new tag");
      useSidecar.tagAdded(tag);
    } else {
      // If the tag is not from a content taxonomy then we don't need to do anything
      if (!tag.taxonomy.taxonomyType || tag.taxonomy.taxonomyType !== "CONTENT") {
        return;
      }

      // We need to look at the data objects, if we have a data object that would relate to this tag
      // then we need to add an attribute to capture it
      log.info(`Start: Adding tag to data objects using tagUuid ${tag.uuid}, for document family ${documentFamily.id}`);

      let foundDataObject = false;
      log.info(`Checking data objects`);
      for (const dataObject of Array.from(dataObjects.value.values())) {
        log.info(`Checking data object ${dataObject.documentFamily.id} for document family ${documentFamily.id}`);
        if (dataObject.documentFamily.id === documentFamily.id) {
          // We want to compare the path of the tag to the path of the data object
          // if the data object path is the parent of the tag then we are going to
          // create an attribute for it

          log.info(`Checking data object ${dataObject.path} for parent path ${parentPath}`);
          log.info(`Checking data object UUID ${dataObject.uuid} for tag ${tag.groupUuid}`);
          if (parentPath === dataObject.path && tag.groupUuid === dataObject.groupUuid) {
            log.info("Creating a data attribute on the data object, based on the tag");
            await createAttributeFromTag(tag, dataObject);
            foundDataObject = true;
          }
        }
      }

      if (!foundDataObject) {
        // We need to create a new data object
        log.info("Creating a new data object");
        const storeRef = appStore.projectStore.dataStores.length > 0 ? appStore.projectStore.dataStores[0].ref : undefined;
        if (storeRef) {
          // The parent path is the path without the leaf from the tag.taxon.path
          const parentPath = tag.taxon.path?.substr(0, tag.taxon.path.lastIndexOf("/"));

          const dataObject = {
            uuid: uuidv4(),
            path: parentPath,
            taxonomyRef: tag.taxonomy.ref,
            dataExceptions: [],
            storeRef,
            attributes: [],
            documentFamily,
          } as DataObject;

          log.info(`Adding data object ${JSON.stringify(dataObject)}`);

          addDataObject(dataObject);
          await createAttributeFromTag(tag, dataObject);
        }
      }
    }

    // When a tag is added we might also need to capture information for our guidance
    // system, so we will send the tag to the guidance system
    const guidance = appStore.projectStore.guidance;
    if (guidance) {
      guidance.forEach((guidanceSet: GuidanceSet) => {
        appStore.projectStore.buildGuidanceFromTag(guidanceSet, tag, documentFamily);
      });
    }
  }

  async function unlockDocumentFamily(documentFamily: DocumentFamily) {
    if (documentFamily.id) {
      const ref = new RefHelper(documentFamily.storeRef);
      await updateHandler(unlockFamily(ref.getOrgSlug(), ref.getSlug(), documentFamily.id), "Document unlocked");

      // We need to update the document family to remove the lock
      if (documentFamilies.value.has(documentFamily.id)) {
        const docFamily = documentFamilies.value.get(documentFamily.id);
        if (docFamily) {
          docFamily.locked = false;
        }

        // Find all the data objects with this document family and update them
        for (const dataObject of dataObjects.value.values()) {
          if (dataObject.documentFamily.id === documentFamily.id) {
            dataObject.documentFamily.locked = false;
          }
        }
      }
    }
  }

  async function lockDocumentFamily(documentFamily: DocumentFamily) {
    if (documentFamily.id) {
      const ref = new RefHelper(documentFamily.storeRef);
      await updateHandler(lockFamily(ref.getOrgSlug(), ref.getSlug(), documentFamily.id), "Document locked");
      // We need to update the document family to remove the lock
      if (documentFamilies.value.has(documentFamily.id)) {
        const docFamily = documentFamilies.value.get(documentFamily.id);
        if (docFamily) {
          docFamily.locked = true;
        }

        // Find all the data objects with this document family and update them
        for (const dataObject of dataObjects.value.values()) {
          if (dataObject.documentFamily.id === documentFamily.id) {
            dataObject.documentFamily.locked = true;
          }
        }
      }
    }
  }

  function updateFeatureSet(documentFamily: DocumentFamily, featureSet: DocumentFeatureSet) {
    log.info("updateFeatureSet");
    if (documentFamily.id && featureSet) {
      documentFamiliesWithTagChanges.value.set(documentFamily.id as string, featureSet);
    }
  }

  function tagRemoved(tag: SelectedTag, documentFamily: DocumentFamily, featureSet: DocumentFeatureSet) {
    log.info("Tag removed");
    if (documentFamily.id && featureSet) {
      log.info("Updating document family with tag changes");
      documentFamiliesWithTagChanges.value.set(documentFamily.id as string, featureSet);
    } else {
      log.warn("No document family ID");
    }

    if (isSidecar.value && currentWorkspaceId.value) {
      // We need to inform that main workspace that a new tag has been added
      log.info("Informing main workspace of removed tag");
      const useSidecar = createSidecar(currentWorkspaceId.value, true);
      useSidecar.tagRemoved(tag);
    } else {
      log.info(`Removing tag from data objects using tagUuid ${tag.uuid}`);
      // We need to look for the tag in the data objects and remove it
      const attributeUuidsToDelete: string[] = [];
      for (const dataObject of dataObjects.value.values()) {
        if (dataObject.attributes) {
          for (const dataAttribute of dataObject.attributes) {
            if (dataAttribute.tagUuid && dataAttribute.tagUuid === tag.uuid) {
              // We need to delete this data attribute
              if (dataAttribute.uuid) {
                attributeUuidsToDelete.push(dataAttribute.uuid);
              }
            }
          }
        }
      }

      attributeUuidsToDelete.forEach((attributeUuid) => {
        deleteDataAttributeByUuid(attributeUuid);
      });
    }
  }

  function setAsSidecar() {
    isSidecar.value = true;
  }

  function addSiblingDataObjectByUuid(uuid: string) {
    const dataObject = Array.from(dataObjects.value.values()).find(d => d.uuid === uuid);
    if (!dataObject) {
      return;
    }
    const parent = appStore.workspaceStore.findParentDataObject(dataObject);
    if (!parent) {
      return;
    }
    const sibling = {
      uuid: uuidv4(),
      path: dataObject.path,
      dataExceptions: [],
      attributes: [],
      documentFamily: dataObject.documentFamily,
      taxonomyRef: dataObject.taxonomyRef,
      storeRef: parent.storeRef,
      parent,
      parentId: parent.id,
    } as DataObject;

    addDataObject(sibling);
  }

  function addUpdatedAttributeUuid(attribute: DataAttribute) {
    if (attribute.uuid && !updatedAttributeUuids.value.includes(attribute.uuid)) {
      updatedAttributeUuids.value.push(attribute.uuid);
    }
  }

  /**
   * Utility function for getting the list of uuids in the dataException
   * @param exception
   */
  function addUpdatedDataExceptionUuids(exception: DataException) {
    if (!exception) {
      return;
    }

    if (exception.uuid && !updatedDataExceptionsUuids.value.includes(exception.uuid)) {
      updatedDataExceptionsUuids.value.push(exception.uuid);
    }
  }

  /**
   * Utility function for getting the deleted data exception IDs. If the exception has only
   * uuid just remove it from the updatedDataExceptionUuids, but if it contains an id
   * append it in the deletedDataExceptionIds
   * @param exception
   */
  function addDeletedDataExceptionIds(exception: DataException) {
    if (!exception) {
      return;
    }

    updatedDataExceptionsUuids.value = updatedDataExceptionsUuids.value.filter(uuid => uuid !== exception.uuid);

    if (!exception.id || deletedDataExceptionsIds.value.includes(exception.id)) {
      return;
    }
    deletedDataExceptionsIds.value.push(exception.id);
  }

  function addNewDataObjectIds(dataObject: DataObject, dataObjectUuid: string) {
    if (dataObject && dataObjectUuid && !newDataObjectUuids.value.includes(dataObjectUuid)) {
      if (dataObject.parent?.uuid && newDataObjectUuids.value.includes(dataObject.parent.uuid)) {
        const parentDataObjectIndex = newDataObjectUuids.value.indexOf(dataObject.parent.uuid);
        newDataObjectUuids.value.splice(parentDataObjectIndex + 1, 0, dataObjectUuid);
      } else {
        newDataObjectUuids.value.push(dataObjectUuid);
      }
    }
  }

  function buildWorkspaceUpdate() {
    const labelUpdate = getLabelsToUpdate();
    const newDataObjects = newDataObjectUuids.value.map(uuid => findDataObject(uuid)) as DataObjectDTO[];
    return {
      transactionStart: new Date(transactionStart.value).toISOString(),
      labelUpdates: labelUpdate,
      additions: {
        dataObjects: newDataObjects,
      } as WorkspaceAdditions,
      updates: {
        dataAttributes: getUpdatedDataAttributes(newDataObjects),
        dataExceptions: getUpdatedDataExceptions(),
      } as WorkspaceUpdates,
      deletes: {
        dataAttributeIds: deletedAttributeIds.value,
        dataExceptionIds: deletedDataExceptionsIds.value,
        dataObjectIds: deletedDataObjectIds.value,
      } as WorkspaceDeletions,
      featureSets: Array.from(documentFamiliesWithTagChanges.value.values()),
      documentFamilyIds: Array.from(documentFamiliesWithTagChanges.value.keys()),
    } as WorkspaceUpdate;
  }

  const workspaceSaving = ref(false);

  async function saveWorkspaceObjects(): Promise<void> {
    // We will switch out the work to use a workspace endpoint to do it all at once
    // in a transaction
    workspaceSaving.value = true;
    const loadingEvent = {
      id: currentWorkspace.value?.id as string,
      title: "Saving Workspace Objects",
      subtitle: "",
      progress: undefined,
      progressMax: undefined,
    } as LoadingEvent;
    try {
      appStore.platformStore.addLoadingEvent(loadingEvent);
      const workspaceUpdate = buildWorkspaceUpdate();
      // The single endpoint for workspace deletion
      const updateResponse = await updateWorkspaceObjects(currentWorkspace.value?.id as string, workspaceUpdate);

      // We need to update the data objects with the new data object IDs
      updateResponse.dataObjects?.forEach((dataObject) => {
        dataObjects.value.set(dataObject.uuid as string, dataObject);
      });

      if (workspaceUpdate.documentFamilyIds) {
        for (const documentFamilyId of workspaceUpdate.documentFamilyIds) {
          await getDocumentFamilyById(documentFamilyId, true);
        }
      }

      updateResponse.dataAttributes?.forEach((realDataAttribute) => {
        const dataObject = Array.from(dataObjects.value.values()).find(d => d.id === realDataAttribute.dataObjId);
        if (dataObject) {
          if (!dataObject.attributes) {
            dataObject.attributes = [];
          }
          dataObject.attributes = dataObject.attributes.filter(attribute => attribute.uuid !== realDataAttribute.uuid);
          dataObject.attributes.push(realDataAttribute);
        }
      });

      updateResponse.dataExceptions?.forEach((realDataException) => {
        const dataObject = Array.from(dataObjects.value.values()).find(d => d.id === realDataException.dataObjId);
        if (!dataObject) {
          return;
        }
        dataObject.dataExceptions = dataObject.dataExceptions?.filter(exception => exception.uuid !== realDataException.uuid);
        dataObject.dataExceptions?.push(realDataException);
      });

      // Remove all the local state
      updatedAttributeUuids.value = [];
      updatedDataExceptionsUuids.value = [];
      deletedDataExceptionsIds.value = [];
      deletedAttributeIds.value = [];
      newDataObjectUuids.value = [];
      updatedDataObjectUuids.value = [];
      deletedDataObjectIds.value = [];
      newLabels.value = [];
      removedLabels.value = [];
      documentFamiliesInWorkspaceChanged.value = false;
      documentFamiliesWithTagChanges.value.clear();
    } catch (err) {
      notify({
        group: "error",
        title: "Error saving the data",
      }, 5000);
      log.error("Error encountered in save workspace");
      log.error(err);
      throw err;
    } finally {
      appStore.platformStore.removeLoadingEvent(loadingEvent);
      workspaceSaving.value = false;
    }
  }

  function getUpdatedDataAttributes(newDataObjects: DataObjectDTO[]): DataObjectDTO[] {
    const updatedAttributeUuidList: DataObjectDTO[] = [];
    const updatedAttributeUuidsSet = new Set(updatedAttributeUuids.value);
    for (const dataObject of newDataObjects) {
      if (!dataObject.attributes) {
        continue;
      }
      dataObject.attributes.forEach((attribute) => {
        if (attribute.uuid) {
          updatedAttributeUuidsSet.delete(attribute.uuid);
        }
      });
    }
    Array.from(updatedAttributeUuidsSet).forEach((updatedAttributeUuid) => {
      const attributeUuid = findAttributeByUuid(updatedAttributeUuid);
      if (attributeUuid) {
        updatedAttributeUuidList.push(attributeUuid);
      }
    });
    return updatedAttributeUuidList;
  }

  function getLabelsToUpdate() {
    const labelUpdate = {
      documentFamilies: [] as WorkspaceDocument[],
      newLabels: newLabels.value,
      removedLabels: removedLabels.value,
    };

    if (labelUpdate.documentFamilies) {
      for (const documentFamily of documentFamilies.value.values()) {
        if (!documentFamily.id) {
          continue;
        }

        labelUpdate.documentFamilies.push({
          id: documentFamily.id,
          storeRef: documentFamily.storeRef,
        });
      }
    }

    return labelUpdate;
  }

  function clearAttribute(dataObject: DataObject, attribute: DataAttribute) {
    const realDataObject = Array.from(dataObjects.value.values()).find(d => d.uuid === dataObject.uuid);
    if (!realDataObject) {
      return;
    }
    if (!realDataObject.attributes) {
      return;
    }
    const index = realDataObject.attributes.findIndex(a => a.id === attribute.id);
    if (index > -1) {
      // We need to look at the taxon type and clear the value accordingly
      const taxonType = realDataObject.attributes[index].typeAtCreation;
      if (taxonType === DataAttributeTypeAtCreation.CURRENCY) {
        realDataObject.attributes[index].decimalValue = undefined;
      }
      if (taxonType === DataAttributeTypeAtCreation.DATE) {
        realDataObject.attributes[index].dateValue = undefined;
      }
      if (taxonType === DataAttributeTypeAtCreation.DATE_TIME) {
        realDataObject.attributes[index].dateValue = undefined;
      }
      if (taxonType === DataAttributeTypeAtCreation.STRING) {
        realDataObject.attributes[index].stringValue = undefined;
      }

      realDataObject.attributes[index].value = undefined;
    }
  }

  async function deleteAttribute(dataObject: DataObject, attribute: DataAttribute) {
    log.info("Deleting data attribute");
    const realDataObject = dataObjects.value.get(dataObject.uuid as string);
    if (!realDataObject) {
      log.info("No data object found");
      return;
    }
    if (!realDataObject.attributes) {
      log.info("No attributes found");
      return;
    }
    realDataObject.attributes = realDataObject.attributes.filter(a => a.uuid !== attribute.uuid);
    addDeletedAttributeId(realDataObject, attribute);
    dataObjects.value.set(dataObject.uuid as string, realDataObject);

    // Create a selected tag so we can remove it
    const selectedTag = { uuid: attribute.tagUuid, name: attribute.tag, path: attribute.path } as SelectedTag;

    // if we have the document open in a viewer, we need to update the viewer
    const documentView = documentViews.value.find(view => view.documentFamilyId === realDataObject.documentFamily.id);
    if (documentView) {
      log.info("Updating document viewer");
      const documentViewerStore = createDocumentViewerStore(documentView.id);
      if (documentViewerStore) {
        await documentViewerStore.removeTag(selectedTag);
      } else {
        log.info("Unable to find document viewer store");
      }
    }

    if (isSidecar.value && currentWorkspaceId.value) {
      const useSidecar = createSidecar(currentWorkspaceId.value, true);
      log.info("Informing main workspace of new tag");
      useSidecar.tagRemoved(selectedTag);
    }
  }

  function addDeletedAttributeId(dataObject: DataObject, attribute: DataAttribute) {
    if (!attribute) {
      return;
    }

    // Remove the data attribute in updatedAttributeUuids first
    updatedAttributeUuids.value = updatedAttributeUuids.value.filter(uuid => uuid !== attribute.uuid);
    if (!attribute.id || deletedAttributeIds.value.includes(attribute.id)) {
      return;
    }
    deletedAttributeIds.value.push(attribute.id);
  }

  function setFocusedAttribute(attribute: DataAttribute) {
    focusedAttribute.value = attribute;
  }

  function setFocusedNodeUuid(uuid: string) {
    focusedNodeUuid.value = uuid;
  }

  function setFocusTagUuid(uuid: string) {
    focusTagUuid.value = uuid;
  }

  /**
   * This function will poll the server for updates to the data objects and document families, we
   * don't do this inside the store since the lifecycle hooks are a little tricky and we don't want to be
   * polling if the store is not being used in visible component
   */
  async function poll() {
    if (!currentWorkspaceId.value) {
      return;
    }

    const workspaceSequence = await getChangeSequenceForWorkspace(currentWorkspaceId.value);
    if (workspaceSequence && (currentWorkspace.value && workspaceSequence > currentWorkspace.value?.changeSequence)) {
      currentWorkspace.value.changeSequence = workspaceSequence;
    }

    // We need to go through all the document families and determine if they have been updated
    for (const documentFamily of documentFamilies.value.values()) {
      if (documentFamily.id) {
        try {
          const latestSequence = await getChangeSequenceForDocumentFamily(documentFamily.id);
          if (latestSequence && (!documentFamily.changeSequence || latestSequence > documentFamily.changeSequence)) {
            log.info("Document family has been modified, reloading");
            const updatedDocumentFamily = await getDocumentFamilyById(documentFamily.id, true);

            log.info(`Updated document family ${updatedDocumentFamily.changeSequence}`);
            if (updatedDocumentFamily && updatedDocumentFamily.id) {
              documentFamilies.value.set(updatedDocumentFamily.id, updatedDocumentFamily);
              getDataObjectsForDocumentFamilyId(updatedDocumentFamily.id);
            } else {
              log.info("Unable to find updated document family");
            }

            log.info("Check the views to see if we need to reload them");
            for (const view of views.value) {
              if (updatedDocumentFamily && view.viewType === "document" && view.documentFamilyId === updatedDocumentFamily.id) {
                const documentView = createDocumentViewerStore(view.id);
                if (documentView) {
                  await documentView.reload();
                } else {
                  log.info("Unable to find document viewer store");
                }
              }
            }

            if (sidecarPanelOpen.value) {
              // Find the view that is the sidecar
              const sidecarView = documentViews.value.find(view => view.isSidecar);
              if (sidecarView) {
                const documentView = createDocumentViewerStore(sidecarView.id);
                if (documentView) {
                  await documentView.reload();
                } else {
                  log.info("Unable to find document viewer store");
                }
              }
            }

            notify({
              group: "generic",
              title: `Reloaded ${documentFamily.path} and associated data`,
            }, 1000);
          }
        } catch {
          // We had an error, lets remove the document family from the list
          removeDocumentFamily(documentFamily);
        }
      }
    }
  }

  function addAttribute(dataObject: DataObject, attribute: DataAttribute) {
    const realDataObject = Array.from(dataObjects.value.values()).find(d => d.uuid === dataObject.uuid);
    if (!realDataObject) {
      return;
    }
    if (realDataObject.id) {
      attribute.dataObjId = realDataObject.id;
    }
    if (!realDataObject.attributes) {
      realDataObject.attributes = [];
    }

    realDataObject.attributes.push(attribute);

    addUpdatedAttributeUuid(attribute);
  }

  function toggleSidecar() {
    sidecarPanelOpen.value = !sidecarPanelOpen.value;
  }

  function setActiveSelectionView(view: DocumentViewer | DataFormViewer | ExecutionViewer | TextViewer | ProcessingStepViewer | CostViewer | undefined) {
    if (view) {
      activeSelectionView.value = {
        viewId: view.id,
        viewType: view.viewType,
        sidecar: view.isSidecar,
      } as ActiveSelection;
    } else {
      activeSelectionView.value = undefined;
    }
  }

  async function addNewDataObject(tagMetadata: TagMetadata, documentFamily: DocumentFamily | undefined, parentDataObject: DataObject | undefined): Promise<DataObject> {
    const dataObject = {
      uuid: uuidv4(),
      groupUuid: uuidv4(),
      path: tagMetadata.taxon.path,
      dataExceptions: [],
      attributes: [],
      storeRef: parentDataObject?.storeRef,
      documentFamily,
      taxonomyRef: tagMetadata.taxonomy.ref,
    } as DataObject;

    // We need to determine if we have loaded the document, if not we need to load it before we try and
    // add the data object
    if (!documentFamily || !documentFamily.id) {
      log.info("Unable to find document family");
      return dataObject;
    }

    if (!documentFamilies.value.has(documentFamily.id)) {
      log.info("Document family not found, loading");
      await getDocumentFamilyById(documentFamily.id);
    }

    if (parentDataObject && parentDataObject.uuid) {
      dataObject.parent = dataObjects.value.get(parentDataObject?.uuid);
      dataObject.parentId = parentDataObject.id;
    } else {
      log.info("No parent data object, setting root");
    }
    addDataObject(dataObject);
    console.log("Added new data object", dataObject);
    return dataObject;
  }

  /**
   * This function will merge an exception into a data attribute, or data object.  If the exception already
   * exists it won't update it since we don't want to trigger a re-render
   *
   * @param {DataAttribute} dataAttribute
   * @param {DataException} exception
   * @param {DataObject} dataObject
   * @param {boolean} attachToDataObject
   */
  function mergeException(dataAttribute: DataAttribute, exception: DataException, dataObject: DataObject | undefined = undefined, attachToDataObject = false) {
    if (!dataObject) {
      log.error("Unable to attach exception to data object, no data object provided");
      return;
    }
    const realDataObject = dataObjects.value.get(dataObject.uuid as string);
    if (!realDataObject) {
      return;
    }
    if (realDataObject.id) {
      exception.dataObjId = dataObject.id;
    }
    if (attachToDataObject) {
      if (!realDataObject.dataExceptions) {
        realDataObject.dataExceptions = [];
      }
      const existingException = realDataObject.dataExceptions.find(e => e.message === exception.message);
      if (existingException) {
        existingException.exceptionDetails = exception.exceptionDetails;
        if (realDataObject.id) {
          addUpdatedDataExceptionUuids(existingException);
        }
      } else {
        realDataObject.dataExceptions.push(exception);
        if (realDataObject.id) {
          addUpdatedDataExceptionUuids(exception);
        }
      }
    } else {
      const realDataAttribute = findAttributeByUuid(dataAttribute.uuid as string);
      if (!realDataAttribute) {
        return;
      }

      if (!realDataAttribute.dataExceptions) {
        realDataAttribute.dataExceptions = [];
      }
      const existingException = realDataAttribute.dataExceptions.find(e => e.message === exception.message);
      if (existingException) {
        existingException.exceptionDetails = exception.exceptionDetails;
      } else {
        realDataAttribute.dataExceptions.push(exception);
      }
      addUpdatedAttributeUuid(realDataAttribute);
    }
  }

  function removeException(dataAttribute: DataAttribute, exceptionMessage: string, dataObject: DataObject | undefined = undefined, removeFromDataObject = false) {
    // TODO we shouldn't just use the message - we need an exceptionTypeId or something?

    if (removeFromDataObject) {
      log.info("Removing exception from data object");
      if (!dataObject) {
        log.error("Unable to remove exception from data object, no data object provided");
        return;
      }

      let realDataObject: DataObject | undefined;
      if (dataObject.id) {
        realDataObject = Array.from(dataObjects.value.values()).find(d => d.id === dataObject.id);
      } else {
        realDataObject = dataObjects.value.get(dataObject.uuid as string);
      }

      if (!realDataObject) {
        log.info("Unable to find data object");
        return;
      }

      if (!realDataObject.dataExceptions) {
        realDataObject.dataExceptions = [];
      }

      const existingException = realDataObject.dataExceptions.find(e => e.message === exceptionMessage);
      if (existingException) {
        log.info(`Removing exception from data object [${exceptionMessage}]`);
        addDeletedDataExceptionIds(existingException);
        realDataObject.dataExceptions = realDataObject.dataExceptions.filter(e => e.message !== exceptionMessage);
      } else {
        log.info("Unable to find exception on data object");
      }
    } else {
      const realDataAttribute = findAttributeByUuid(dataAttribute.uuid as string);
      if (!realDataAttribute) {
        return;
      }

      if (!realDataAttribute.dataExceptions) {
        realDataAttribute.dataExceptions = [];
      }

      const existingException = realDataAttribute.dataExceptions.find(e => e.message === exceptionMessage);
      if (existingException) {
        // We need to determine if this exception exists on the parent data object to
        const realDataObject = findDataObjectByAttributeUuid(dataAttribute.uuid as string);
        addDeletedDataExceptionIds(existingException);

        realDataAttribute.dataExceptions = realDataAttribute.dataExceptions.filter(e => e.message !== exceptionMessage);
        if (realDataObject && realDataObject.dataExceptions) {
          realDataObject.dataExceptions = realDataObject.dataExceptions.filter(e => e.message !== exceptionMessage);
        }
      }
    }
  }

  function updateAttribute(dataObject: DataObject, attribute: DataAttribute) {
    if (!dataObject.uuid) {
      return;
    }
    const realDataObject = dataObjects.value.get(dataObject.uuid);
    if (!realDataObject || !realDataObject.attributes) {
      return;
    }

    const index = realDataObject.attributes.findIndex(a => a.uuid === attribute.uuid);
    if (index > -1) {
      realDataObject.attributes[index] = attribute;
    }

    // We need to determine if we have the document open and if we do we need to
    // update the value on the tag
    const documentView = documentViews.value.find(view => view.documentFamilyId === realDataObject.documentFamily.id);
    if (documentView) {
      const documentViewerStore = createDocumentViewerStore(documentView.id);
      if (documentViewerStore) {
        documentViewerStore.updateTagValue(attribute);
      }
    }

    addUpdatedAttributeUuid(attribute);
  }

  /**
   * The function is mainly used for updating the real data exception in the workspace level
   * @param dataObject
   * @param exceptionPartialUpdate
   * @param exceptionUuid
   * @param dataAttribute
   * @param updateFromDataAttribute
   */
  function updateException(dataObject: DataObject, exceptionPartialUpdate: Partial<DataException>, exceptionUuid: string, dataAttribute: undefined | DataAttribute = undefined, updateFromDataAttribute: false) {
    if (!dataObject.uuid) {
      return;
    }

    const realDataObject = dataObjects.value.get(dataObject.uuid);

    if (updateFromDataAttribute) {
      if (!dataAttribute) {
        return;
      }
      const realDataAttribute = findAttributeByUuid(dataAttribute.uuid as string);

      if (!realDataAttribute?.dataExceptions) {
        return;
      }
      const realDataException = realDataAttribute.dataExceptions.find(e => e.uuid === exceptionUuid);
      if (!realDataException) {
        return;
      }

      if (diffDataExceptions(realDataException, exceptionPartialUpdate)) {
        const realDataException = realDataAttribute.dataExceptions.find(e => e.uuid === exceptionUuid);
        if (realDataException) {
          Object.assign(realDataException, exceptionPartialUpdate);
          realDataAttribute.dataExceptions = realDataAttribute.dataExceptions.filter(e => e.uuid !== exceptionUuid);
          realDataAttribute.dataExceptions.push(realDataException);
          if (realDataObject?.id) {
            addUpdatedDataExceptionUuids(realDataException);
          }
        }
      }
    } else {
      if (!realDataObject?.dataExceptions) {
        return;
      }
      const realDataException = realDataObject.dataExceptions.find(e => e.uuid === exceptionUuid);
      if (!realDataException) {
        return;
      }
      if (diffDataExceptions(realDataException, exceptionPartialUpdate)) {
        const realDataException = realDataObject.dataExceptions.find(e => e.uuid === exceptionUuid);
        if (realDataException) {
          Object.assign(realDataException, exceptionPartialUpdate);
          realDataObject.dataExceptions = realDataObject.dataExceptions.filter(e => e.uuid !== exceptionUuid);
          realDataObject.dataExceptions.push(realDataException);
          if (realDataObject?.id) {
            addUpdatedDataExceptionUuids(realDataException);
          }
        }
      }
    }
  }

  /**
   * Used to compare if there is something to be updated on the following objectKeys
   * @param originalException: Original DataException
   * @param updatedException: DataException to upate
   */
  function diffDataExceptions(originalException: DataException, updatedException: Partial<DataException>) {
    const objectKeys = ["open", "message", "exceptionDetails"];
    for (const objectKey of objectKeys) {
      if (originalException[objectKey] !== updatedException[objectKey]) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * @param message: The exception message
   * @param dataAttribute: The data attribute containing the dataExceptions
   */
  function findDataAttributeExceptionByMessage(message: string, dataAttribute: DataAttribute): DataException | undefined {
    if (!dataAttribute.dataExceptions) {
      return undefined;
    }
    return dataAttribute.dataExceptions.find(e => e.message === message) as DataException | undefined;
  }

  function addAttributeToDataObject(dataObject: DataObject, taxon: Taxon, value: any) {
    const attribute = {
      uuid: uuidv4(),
      taxon,
      value,
      dataObject: {
        uuid: dataObject.uuid,
        documentFamily: {
          id: dataObject.documentFamily?.id,
          path: dataObject.documentFamily?.path,
        },
      },
      tag: taxon.name,
    } as DataAttribute;

    if (taxon.taxonType === "CURRENCY") {
      attribute.decimalValue = Number.parseFloat(value);
    }
    if (taxon.taxonType === "NUMBER") {
      attribute.decimalValue = Number.parseFloat(value);
    }
    if (taxon.taxonType === "DATE") {
      attribute.dateValue = value;
    }
    if (taxon.taxonType === "DATE_TIME") {
      attribute.dateValue = value;
    }
    if (taxon.taxonType === "BOOLEAN") {
      attribute.booleanValue = value;
    }
    if (taxon.taxonType === "STRING") {
      attribute.stringValue = value;
    }

    addAttribute(dataObject, attribute);
  }

  async function deleteDocumentFamilyById(documentFamilyId: string) {
    const realDocumentFamily = documentFamilies.value.get(documentFamilyId);
    if (realDocumentFamily) {
      await removeDocumentFamily(realDocumentFamily);
    }

    await deleteDocumentFamily(documentFamilyId);
  }

  function startTransaction() {
    transactionStart.value = Date.now();
  }

  /**
   * This function will add a taxonomyRef as updated by the workspace
   */
  function addUpdatedTaxonomyRef(taxonomyRef: string) {
    if (!updatedTaxonomyRefs.value.includes(taxonomyRef)) {
      appStore.projectStore.addUpdatedTaxonomyRef(taxonomyRef);
    }
  }

  function updateTaxonomy(taxonomy: Taxonomy) {
    appStore.projectStore.uploadLocalTaxonomy(taxonomy);
    addUpdatedTaxonomyRef(taxonomy.ref as string);
  }

  async function saveUpdatedTaxonomies() {
    for (const taxonomyRef of updatedTaxonomyRefs.value) {
      await appStore.projectStore.saveTaxonomyByRef(taxonomyRef);
    }
  }

  const workspaceSaveChanges = createConfirmDialog(KodexaDeleteConfirm);

  async function showSaveWarning() {
    // We need to determine if the project or workspace is dirty first?

    const { projectDirty } = storeToRefs(appStore.projectStore);

    if (!isDirty.value && !(projectDirty.value === true)) {
      return;
    }

    log.info(`Showing save warning for workspace isDirty:${isDirty.value} projectDirty:${projectDirty.value}`);

    const response = await workspaceSaveChanges.reveal({
      title: "Save changes?",
      message: "You have changes in the workspace, if not saved they will not be available to the platform in processing.",
      acceptClasses: ["bg-blue-600"],
      confirmText: "Save Changes",
      cancelText: "Continue without Saving",
      icon: "content-save",
      confirmIcon: "content-save",
      iconClasses: ["text-gray-600"],
    });

    if (!response.isCanceled) {
      await saveWorkspace();
      await saveWorkspaceObjects();
    }
  }

  function clearWorkspaceData() {
    updatedAttributeUuids.value = [];
    updatedDataExceptionsUuids.value = [];
    deletedDataExceptionsIds.value = [];
    deletedAttributeIds.value = [];
    newDataObjectUuids.value = [];
    updatedDataObjectUuids.value = [];
    deletedDataObjectIds.value = [];
    newLabels.value = [];
    removedLabels.value = [];
    documentFamiliesInWorkspaceChanged.value = false;
    documentFamiliesWithTagChanges.value = new Map();
    documentFamilies.value = new Map();
    dataObjects.value = new Map();
  }

  function findDataObjectsByFamilyId(documentFamilyId: string) {
    const dataObjectsByFamilyId: DataObject[] = [];
    for (const dataObject of dataObjects.value.values()) {
      if (dataObject.documentFamily.id === documentFamilyId) {
        dataObjectsByFamilyId.push(dataObject);
      }
    }
    return dataObjectsByFamilyId;
  }

  function formatXml(xml, tab) { // tab = optional indent value, default is tab (\t)
    let formatted = "";
    let indent = "";
    tab = tab || "\t";
    xml.split(/>\s*</).forEach((node) => {
      if (node.match(/^\/\w/)) {
        indent = indent.substring(tab.length);
      } // decrease indent by one 'tab'
      formatted += `${indent}<${node}>\r\n`;
      if (node.match(/^<?\w[^>]*[^/]$/)) {
        indent += tab;
      } // increase indent
    });
    return formatted.substring(1, formatted.length - 3);
  }

  async function getDataObjectsWithFormat(documentFamily: DocumentFamily, format = "json") {
    const friendlyNames = ref(true);
    if (format === "xml(friendly)") {
      friendlyNames.value = true;
      format = "xml";
    } else {
      friendlyNames.value = false;
    }
    const url = `/api/stores/${documentFamily.storeRef.replace(":", "/")}/families/${documentFamily.id}/dataObjects`;
    const response = await axios.get(url, {
      method: "GET",
      params: {
        format,
        friendlyNames: friendlyNames.value,
        projectId: appStore.projectStore.project.id,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userJwtToken")}`,
      },
    });
    if (format === "json") {
      return JSON.stringify(response.data, null, 2);
    } else {
      return formatXml(response.data, "\t");
    }
  }

  async function getExportForDocument(documentFamily: DocumentFamily, format = "json", executionId: string | undefined = undefined) {
    if (executionId) {
      // If we have an execution ID then we need to get the data objects from the document
      // that is part of the execution
      const executionViewer = createDocumentViewerStore(executionId);
      const document = executionViewer.kddbDocument;
      const url = `/api/extractionEngine/extract`;

      // Create an instance of FormData
      const formData = new FormData();
      formData.append("document", document.toBlob());
      formData.append("taxonomiesJson", appStore.projectStore.getTaxonomiesJson());

      const response = await axios.post(url, formData, {
        method: "POST",
        params: {
          format,
        },
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${localStorage.getItem("userJwtToken")}`,
        },
      });
      if (format === "json") {
        return JSON.stringify(response.data, null, 2);
      } else {
        return formatXml(response.data, "\t");
      }
    } else {
      const friendlyNames = ref(true);
      if (format === "xml(friendly)") {
        friendlyNames.value = true;
        format = "xml";
      } else {
        friendlyNames.value = false;
      }
      const url = `/api/stores/${documentFamily.storeRef.replace(":", "/")}/families/${documentFamily.id}/dataObjects`;
      const response = await axios.get(url, {
        method: "GET",
        params: {
          format,
          friendlyNames: friendlyNames.value,
          projectId: appStore.projectStore.project.id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userJwtToken")}`,
        },
      });
      if (format === "json") {
        return JSON.stringify(response.data, null, 2);
      } else {
        return formatXml(response.data, "\t");
      }
    }
  }

  async function sendTemplateMessage(messageTemplate: MessageTemplate, activeSelection: ActiveSelection | undefined) {
    // Set the active sidebar to chat
    appStore.platformStore.currentSidebar = "channel";

    const channel = {
      workspace: { id: currentWorkspace.value?.id },
      name: "New Chat",
      participants: [
        { user: appStore.userStore.user as User } as ChannelParticipant,
        { assistant: messageTemplate.assistant as Assistant } as ChannelParticipant,
      ],
      isPrivate: false,
    } as Channel;

    // We need to create a new channel and then send the message
    const newChannel = await createChannel(channel);

    log.info(`Sending template message with channel ${newChannel.id}`);
    const channelStore = createChannelStore(newChannel.id as string);
    let context = {} as MessageContext;
    if (activeSelection) {
      const newContext = channelStore.getContext(activeSelection);
      if (newContext) {
        context = newContext as MessageContext;
      }
    } else {
      context = {} as MessageContext;
    }
    context.messageTemplate = messageTemplate;

    const user = appStore.userStore.user as User;

    const userBlock = {
      type: "complete",
      properties: {
        text: messageTemplate.content || "Sending request to assistant...",
      },
    } as MessageBlock;

    const message = {
      messageType: "TEMPLATE",
      uuid: uuidv4(),
      user,
      content: JSON.stringify(messageTemplate),
      context,
      channel: {
        id: newChannel.id,
      },
      block: userBlock,
    } as Message;

    activeChannelId.value = newChannel.id as string;

    await channelStore.sendMessage(message);
  }

  function setActiveChannelId(channelId: string) {
    activeChannelId.value = channelId;
  }

  async function retrainModels() {
    await appStore.projectStore.retrainModels();
  }

  function updateCurrentWorkspace(workspace: Workspace) {
    workspaceDirty.value = true;
    currentWorkspace.value = workspace;
  }

  function getWorkspaceDataObjectIfAvailable(dataObject: DataObject) {
    return dataObjects.value.has(dataObject.uuid as string) ? dataObjects.value.get(dataObject.uuid as string) : dataObject;
  }

  function loadDataObject(dataObject: DataObject) {
    if (dataObject.uuid && !dataObjects.value.has(dataObject.uuid)) {
      dataObjects.value.set(dataObject.uuid, dataObject);
    }
  }

  function getStoreByRef(storeRef: string) {
    return appStore.projectStore.getStoreByRef(storeRef);
  }

  function updateAttributeProperties(attribute: DataAttribute, properties: any) {
    const realDataAttribute = findAttributeByUuid(attribute.uuid as string);
    if (!realDataAttribute) {
      return;
    }
    realDataAttribute.dataFeatures = properties;
    addUpdatedAttributeUuid(realDataAttribute);
  }

  async function getExternalData(documentFamily: DocumentFamily) {
    return await getDocumentFamilyExternalData(documentFamily.id as string);
  }

  const disabledTaxonomies: Ref<string[]> = ref([]);

  function disableTaxonomy(taxonomy: Taxonomy) {
    if (!disabledTaxonomies.value.includes(taxonomy.ref)) {
      disabledTaxonomies.value.push(taxonomy.ref);
    }
  }

  function enableTaxonomy(taxonomy: Taxonomy) {
    if (disabledTaxonomies.value.includes(taxonomy.ref)) {
      disabledTaxonomies.value = disabledTaxonomies.value.filter(ref => ref !== taxonomy.ref);
    }
  }

  async function toggleGuidance(documentFamily: DocumentFamily) {
    await toggleGuidanceOnDocumentFamily(documentFamily?.id);
  }

  async function removeAllFromWorkspace() {
    for (const documentFamily of documentFamilies.value.values()) {
      await removeDocumentFamily(documentFamily);
    }
  }

  return {
    disabledTaxonomies,
    enableTaxonomy,
    disableTaxonomy,
    getExternalData,
    startTransaction,
    removeDocumentFamily,
    openDocumentView,
    isDocumentViewOpen,
    addDocumentFamily,
    addDataForm,
    loadWorkspace,
    removeViewById,
    setActiveView,
    getDocumentFamily,
    getExportForDocument,
    hasDocumentFamilyById,
    getDocumentFamilyById,
    workspaceSidebar,
    currentWorkspaceId,
    gotoTagInstance,
    openWorkspaceForDocumentFamily,
    dataObjects,
    views,
    getTitle,
    getViewById,
    activeView,
    addView,
    openWorkspace,
    deleteWorkspaceById,
    createNewWorkspace,
    openDataForm,
    documentFamilies,
    addDataObject,
    findAttribute,
    findAttributeByUuid,
    findDataObjectByAttributeUuid,
    getLatestContentObject,
    deleteDataObjectByUuid,
    findParentDataObject,
    tagAdded,
    tagRemoved,
    updateFeatureSet,
    setAsSidecar,
    addSiblingDataObjectByUuid,
    clearAttribute,
    addUpdatedAttributeUuid,
    saveWorkspaceObjects,
    saveWorkspace,
    deleteAttribute,
    focusedAttribute,
    setFocusedAttribute,
    focusedNodeUuid,
    setFocusedNodeUuid,
    addAttribute,
    poll,
    sidecarPanelOpen,
    toggleSidecar,
    activeSelectionView,
    setActiveSelectionView,
    focusTagUuid,
    setFocusTagUuid,
    addLabel,
    removeLabel,
    updateAttribute,
    updateDataObject,
    isSidecarOpen,
    addNewDataObject,
    mergeException,
    removeException,
    clearCurrentWorkspace,
    addAttributeToDataObject,
    currentWorkspace,
    deleteDocumentFamilyById,
    isDirty,
    unlockDocumentFamily,
    lockDocumentFamily,
    updateException,
    updateTaxonomy,
    addUpdatedTaxonomyRef,
    updatedTaxonomyRefs,
    findChildDataObject,
    createTextViewer,
    buildWorkspaceUpdate,
    saveUpdatedTaxonomies,
    showSaveWarning,
    clearWorkspaceData,
    findDataObjectsByFamilyId,
    sidecarViews,
    sendTemplateMessage,
    activeChannelId,
    setActiveChannelId,
    retrainModels,
    availablePanels,
    updateCurrentWorkspace,
    findDataAttributeExceptionByMessage,
    getWorkspaceDataObjectIfAvailable,
    loadDataObject,
    getStoreByRef,
    setActiveSelectionViewById,
    getViewMetadata,
    workspaceSaving,
    updateAttributeProperties,
    removeDataForm,
    clearDataForm,
    getDataObjectsWithFormat,
    toggleGuidance,
    removeAllFromWorkspace,
  };
})
;
