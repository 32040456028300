<script lang="ts" setup>
import type { PropType } from "vue";
import appStore from "~/store";

const props = defineProps({
  keyName: {
    type: String,
    required: true,
  },
  node: {
    type: Object as PropType<Node>,
  },
  nodeColor: {
    type: String,
    default: "#23ABDC",
  },
  textColor: {
    type: String,
    default: "white",
  },
});

function openGuidance() {
  appStore.workspaceStore.createTextViewer(`${props.node.data.clean.label} Guidance`, props.node.data.clean.prompt);
}
</script>

<template>
  <KodexaButton
    v-tooltip="`Open Guidance`"
    class="open-guidance"
    name="openGuidance"
    size="medium"
    icon="school-outline"
    @click="openGuidance"
  />
</template>

<style scoped>
.open-guidance {
  height: 40px;
  width: 40px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  background-color: white !important;
  color: v-bind(nodeColor) !important;
  border-radius: 100px;
  border: 1px solid v-bind(nodeColor);
}

.open-guidance:hover {
  opacity: 0.8;
}
</style>
