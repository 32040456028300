<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue';
import { storeToRefs } from "pinia";
import type { PropType } from "vue";
import type { DataAttribute, DataObject } from "~/model";
import appStore from "~/store";
import type { TagMetadata } from "~/store/useProject";
import type { AttributeEditorOptions } from "~/components/dataObject/attribute-editor-options";
import { getAttributeValueByTaxonType } from "~/components/util/attribute-utils";
import { TreeView, TreeViewDragAnalyzer, TreeViewDragClue, processTreeViewItems } from "@progress/kendo-vue-treeview";
import { Toolbar } from "@progress/kendo-vue-buttons";
import KodexaDataAttributeEditor from "~/components/dataObject/kodexa-data-attribute-editor.vue";
import KodexaReadOnlyAttribute from "~/components/dataObject/kodexa-read-only-attribute.vue";

const props = defineProps({
  groupTaxonMetadata: {
    type: Object as PropType<TagMetadata>,
    required: true,
  },
  dataObjects: {
    type: Array as PropType<DataObject[]>,
    required: true,
  },
  parentDataObject: {
    type: Object as PropType<DataObject>,
    required: false,
    default: null,
  },
  tabIndex: {
    type: Number,
    required: false,
  },
  isLoading: Boolean,
  isError: Boolean,
  error: Object,
  viewId: String,
  showHeader: {
    type: Boolean,
    default: true,
  },
});

const { sidecarPanelOpen } = storeToRefs(appStore.workspaceStore);
const { tagMetadataMap } = storeToRefs(appStore.projectStore);
const { currentWorkspaceId } = storeToRefs(appStore.workspaceStore);

const editorOptions = ref<AttributeEditorOptions>({ showPreview: false });
const expand = ref({ ids: [] as string[], idField: "id" });
const isDragging = ref(false);
const isDropped = ref(false);
const dragClue = ref<any | null>(null);
const droppedItemId = ref<string | null>(null);
const searchQuery = ref('');
const originalExpandState = ref<string[]>([]);
const showLabelsWithNoValues = ref(false);

const ensureRootInitiallyExpanded = () => {
  const rootId = props.groupTaxonMetadata?.taxon?.id;
  if (rootId && !originalExpandState.value.includes(rootId)) {
    originalExpandState.value.push(rootId);
    expand.value.ids.push(rootId);
  }
};

onMounted(ensureRootInitiallyExpanded);

watch(() => props.groupTaxonMetadata, ensureRootInitiallyExpanded, { deep: true });

const buildTreeItem = (item: any, dataObj: DataObject | null = null, depth = 0, counter = 1, parent: any = null) => {
  if (depth > 10 || item.notUserLabelled) return null;

  const treeItem = {
    id: item.id,
    label: item.label,
    path: item.path,
    taxon: item,
    dataObject: dataObj,
    value: null,
    children: [],
    hasChildren: false,
    parent,
    hasAttributeType: false,
    originallyHadChildren: item.children && item.children.length > 0
  };

  // Log taxon information
  console.log('Taxon:', {
    label: item.label,
    path: item.path,
    taxonType: item.taxonType
  });

  if (item.children?.length > 0) {
    treeItem.hasChildren = true;
    let currentParent = parent;
    while (currentParent) {
      currentParent.hasChildren = true;
      currentParent = currentParent.parent;
    }
  }

  if (dataObj?.attributes) {
    const matchingAttr = dataObj.attributes.find(attr => attr.path === item.path);
    if (matchingAttr) {
      treeItem.value = getAttributeValueByTaxonType(matchingAttr, tagMetadataMap.value);
      treeItem.hasAttributeType = true;

      // Log attribute information, including those with no value
      console.log('Attribute:', {
        label: item.label,
        path: item.path,
        type: matchingAttr.typeAtCreation,
        value: treeItem.value,
        hasValue: treeItem.value !== null && treeItem.value !== undefined && treeItem.value !== ''
      });
    } else {
      // Log attributes with no matching value
      console.log('Attribute with no value:', {
        label: item.label,
        path: item.path,
        type: item.taxonType,
        value: null,
        hasValue: false
      });
    }
  }

  if (item.children) {
    const dataObjects = props.dataObjects.filter(obj => obj.path === item.path);

    if (dataObjects.length > 0) {
      dataObjects.forEach((dataObj, index) => {
        const dataObjectNode = {
          id: dataObj.id,
          label: `${item.label} ${index + 1}`,
          path: item.path,
          taxon: item,
          dataObject: dataObj,
          children: [],
          hasChildren: item.children.length > 0,
          parent: treeItem,
          hasAttributeType: false,
          originallyHadChildren: true
        };

        item.children.forEach(child => {
          const childNode = buildTreeItem(child, dataObj, depth + 1, index + 1, dataObjectNode);
          if (childNode) dataObjectNode.children.push(childNode);
        });

        if (dataObjectNode.children.length > 0 || dataObjectNode.hasAttributeType) {
          if (dataObjectNode.children.length === 1 && dataObjectNode.children[0].hasChildren) {
            const grandChild = dataObjectNode.children[0];
            grandChild.parent = treeItem;
            treeItem.children.push(grandChild);
          } else {
            treeItem.children.push(dataObjectNode);
          }
        }
      });
    } else {
      item.children.forEach(child => {
        const childNode = buildTreeItem(child, null, depth + 1, 1, treeItem);
        if (childNode) treeItem.children.push(childNode);
      });
    }
  }

  if (treeItem.originallyHadChildren && treeItem.children.length === 0 && !treeItem.hasAttributeType) {
    return null;
  }

  if (treeItem.children.length === 1 && treeItem.children[0].hasChildren) {
    const [grandChild] = treeItem.children;
    treeItem.children = grandChild.children;
    treeItem.children.forEach(child => child.parent = treeItem);
  }

  return treeItem;
};

const treeDataItems = computed(() => {
  if (!props.groupTaxonMetadata?.taxon) return [];

  const rootItem = buildTreeItem(props.groupTaxonMetadata.taxon);
  return rootItem ? processTreeViewItems([rootItem], { expand: expand.value, childrenField: 'children' }) : [];
});

const isSelectionAttribute = (item: any): boolean => {
  if (item.dataObject?.attributes) {
    const matchingAttr = item.dataObject.attributes.find(attr => attr.path === item.path);
    return matchingAttr?.typeAtCreation === 'SELECTION';
  }
  return false;
};

const onExpandChange = (event: any) => {
  const index = expand.value.ids.indexOf(event.item.id);
  if (index === -1) {
    expand.value.ids.push(event.item.id);
    if (!searchQuery.value) {
      originalExpandState.value.push(event.item.id);
    }
  } else {
    expand.value.ids.splice(index, 1);
    if (!searchQuery.value) {
      const originalIndex = originalExpandState.value.indexOf(event.item.id);
      if (originalIndex !== -1) {
        originalExpandState.value.splice(originalIndex, 1);
      }
    }
  }
};

const onItemDragEnd = (event: any) => {
  isDragging.value = false;
  isDropped.value = false;
  dragClue.value?.hide();
  new TreeViewDragAnalyzer(event).init();

  droppedItemId.value = event.item.id;
  setTimeout(() => droppedItemId.value = null, 2000);
};

const onItemDragOver = (event: any) => {
  isDragging.value = true;
  const eventAnalyzer = new TreeViewDragAnalyzer(event).init();
  if (dragClue.value && eventAnalyzer.isDropAllowed) {
    dragClue.value.show(event.pageY - 110, event.pageX, event.item.label, "k-i-insert-middle");
  }
};

const toggleSidecar = () => appStore.workspaceStore.toggleSidecar();

const showInContext = (item: any) => {
  if (item.dataObject && item.taxon) {
    const useSidebar = createSidecar(currentWorkspaceId.value);
    const matchingAttribute = item.dataObject.attributes.find(
      (attr: DataAttribute) => attr.path === item.taxon.path
    );
    useSidebar.focusAttribute(matchingAttribute || item.taxon, item.dataObject);
  }
};

const getIconName = (item: any): string => {
  if (item.taxon.group || item.taxon.taxonType === 'DATA_OBJECT') return 'folder-table-outline';
  if (item.taxon.taxonType === 'SECTION') return 'minus';
  if (item.taxon.valuePath === 'FORMULA') return 'math-integral-box';
  if (item.taxon.valuePath === 'METADATA') return 'file-document-outline';
  return 'tag';
};

const getAttributeComponent = (item: any) => {
  return isSelectionAttribute(item) ? KodexaDataAttributeEditor : KodexaReadOnlyAttribute;
};

const shouldShowAttribute = (item: any): boolean => {
  return item.dataObject && tagMetadataMap.value.has(item.path) && !item.hasChildren;
};

const filteredTreeDataItems = computed(() => {
  if (!searchQuery.value) {
    expand.value.ids = [...originalExpandState.value];
    return treeDataItems.value;
  }

  if (originalExpandState.value.length === 0) {
    originalExpandState.value = [...expand.value.ids];
  }

  const filterTree = (items, path = []) => {
    return items.reduce((acc, item) => {
      const matchesSearch = item.label.toLowerCase().includes(searchQuery.value.toLowerCase());
      let keepItem = matchesSearch;
      const currentPath = [...path, item.id];

      if (item.children && item.children.length) {
        const filteredChildren = filterTree(item.children, currentPath);
        if (filteredChildren.length > 0) {
          item = { ...item, children: filteredChildren };
          keepItem = true;
        }
      }

      if (keepItem) {
        currentPath.forEach(id => {
          if (!expand.value.ids.includes(id)) {
            expand.value.ids.push(id);
          }
        });
        acc.push(item);
      }

      return acc;
    }, []);
  };

  const filteredItems = filterTree(treeDataItems.value);
  ensureRootInitiallyExpanded();
  return filteredItems;
});

watch(searchQuery, (newValue, oldValue) => {
  if (newValue === '' && oldValue !== '') {
    expand.value.ids = [...originalExpandState.value];
  }
});
</script>

<template>
  <div>
    <div class="mt-2">
      <Toolbar :class="{ 'bg-white border-0': showHeader, 'bg-gray-100': !showHeader }">
        <KodexaTextInput
          v-model="searchQuery"
          :show-clear="true"
          type="text"
          name="filterTree"
          class="mr-2 -ml-1 block w-96 rounded-md border-gray-300 shadow-sm sm:text-sm"
          placeholder="Filter Tree"
        />
        <KodexaButton
          :icon="sidecarPanelOpen ? 'close' : 'dock-right'"
          size="small"
          :title="sidecarPanelOpen ? 'Close Sidecar' : 'Open Sidecar'"
          type="secondary"
          @click="toggleSidecar"
        >
          {{ sidecarPanelOpen ? 'Close' : 'Open' }} Sidecar
        </KodexaButton>
      </Toolbar>
      <div class="sm:col-span-2">
        <KodexaCheckbox
          v-model="showLabelsWithNoValues"
          label="Show labels with no value"
          name="noValue"
        />
      </div>
      <div class="sm:col-span-2">
        <KodexaCheckbox
          label="Limit to page"
          name="limitToPage"
        />
      </div>
      <hr class="my-5">
      <TreeViewDragClue ref="dragClue" />
      <TreeView
        :data-items="filteredTreeDataItems"
        text-field="label"
        children-field="children"
        :expand-icons="true"
        :draggable="true"
        item="item"
        @itemdragover="onItemDragOver"
        @itemdragend="onItemDragEnd"
        @expandchange="onExpandChange"
      >
        <template #item="{ props }">
          <div
            :class="{
              'dragging-indicator': isDragging,
              'dropped-indicator': droppedItemId === props.item.id,
            }"
            @click="showInContext(props.item)"
          >
            <span>
              <MaterialDesignIcon
                :name="getIconName(props.item)"
                size="14"
                class="mr-1"
                :style="{ color: props.item.taxon.color }"
              />
              <span>{{ props.item.label || "Missing Name" }}</span>
            </span>
            <component
              :is="getAttributeComponent(props.item)"
              v-if="shouldShowAttribute(props.item)"
              :view-id="viewId"
              :tag-metadata="tagMetadataMap.get(props.item.path)"
              :data-object="props.item.dataObject"
              :editor-options="editorOptions"
            />
          </div>
        </template>
      </TreeView>
    </div>
  </div>
</template>

<style scoped>
.dragging-indicator {
  opacity: 0.7;
  border-radius: 5px;
}

.dropped-indicator {
  border: 2px solid blue;
  animation: blinking 1s infinite;
  transition: border-color 0.3s ease-in-out;
}

@keyframes blinking {
  0% { border-color: blue; }
  50% { border-color: transparent; }
  100% { border-color: blue; }
}

:deep(.k-treeview-leaf) {
  width: 300px !important;
  display: block !important;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
}

.dropped-indicator {
  border: none;
}

:deep(.k-treeview-leaf:focus),
:deep(.k-treeview-leaf.k-focus) {
  box-shadow: none;
}

:deep(.k-animation-container) {
  z-index: auto !important;
}
</style>
