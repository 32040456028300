<script lang="ts" setup>
import type { PropType } from "vue";
import { storeToRefs } from "pinia";
import type { Assistant, DataFlowNode } from "~/model";
import appStore from "~/store";
import KodexaTextInput from "~/components/inputs/kodexa-text-input.vue";

const props = defineProps({
  node: {
    type: Object as PropType<DataFlowNode>,
  },
});

const emit = defineEmits(["update-model-options", "step-deleted"]);

const modelStore = computedAsync(async () => {
  return await appStore.platformStore.getModel(props.node.id.split("//")[1]);
}, null);

const currentNavigation = ref({ ref: "general", name: "General", icon: "cog" });

const tabs = computed(() => {
  return {
    general: { ref: "general", name: "General" },
    options: { ref: "options", name: "Options" },
  };
});

const { assistants } = storeToRefs(appStore.projectStore);

const localAssistant = computed(() => {
  return assistants.value.find((assistant: Assistant) => props.node && assistant.id === props.node.assistant.id);
});

const localOptions = computed({
  get: () => {
    return localAssistant.value?.options?.pipeline?.steps[props.node.idx]?.options || {};
  },
  set: (value) => {
    const idx = props.node.idx;
    // We want to replace the options for the model at idx in the assistant pipeline in options
    const assistantCopy = JSON.parse(JSON.stringify(localAssistant.value));
    assistantCopy.options.pipeline.steps[idx].options = value;
    appStore.projectStore.addToAssistantsToUpdate(assistantCopy);
  },
});

function deleteStep() {
  const idx = props.node.idx;
  localAssistant.value.options.pipeline.steps.splice(idx, 1);
  appStore.projectStore.addToAssistantsToUpdate(localAssistant.value);
  emit("step-deleted");
}
</script>

<template>
  <div>
    <div v-if="modelStore" class="flex h-full flex-col bg-white">
      <div>
        <div class="border-b border-gray-200 dark:border-gray-700">
          <ul class="-mb-px ml-2 flex flex-wrap text-center text-sm font-medium text-gray-500 dark:text-gray-400">
            <li
              v-for="(item) in tabs" :key="item.ref"
              class="mr-2"
              @click="currentNavigation = item"
            >
              <a
                class="text-md"
                :class="item.ref === currentNavigation?.ref ? 'inline-flex items-center justify-center p-4 text-theme-primary border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group' : 'inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'"
              >
                <MaterialDesignIcon
                  v-if="item?.icon" :name="item.icon" size="18"
                  class="text-theme-primary mr-3"
                />
                {{ item?.name }}
              </a>
            </li>
          </ul>
        </div>
        <div v-if="currentNavigation?.ref === 'general'" class="mx-2 mt-2">
          <div class="col-span-6 mb-1 sm:col-span-3">
            <KodexaTextInput
              v-model="modelStore.name"
              name="name"
              type="text"
              autocomplete="modelStore-name"
              label="Name"
            />
          </div>
          <KodexaButton
            icon="delete"
            type="danger"
            class="mt-4"
            @click="deleteStep"
          >
            Remove Model from Pipeline
          </KodexaButton>
        </div>
        <div v-if="currentNavigation?.ref === 'options'" class="mx-2 mt-2">
          <div class="col-span-6 mb-1 sm:col-span-3">
            <div v-for="option in modelStore.metadata.inferenceOptions" :key="option.name">
              <ConfigurationOption
                v-model="localOptions"
                :assistant="node.assistant"
                :item="option"
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
