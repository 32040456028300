<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import type { PropType } from "vue";
import type { Guidance, GuidanceSet } from "~/model";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  guidance: {
    type: Object as PropType<Guidance>,
    required: false,
  },
  guidanceSet: {
    type: Object as PropType<GuidanceSet>,
    required: false,
  },
});
const emit = defineEmits(["confirm", "cancel", "update:modelValue"]);

const jsonRepresentation = computed({
  get: () => {
    return JSON.stringify(props.guidance, null, 2);
  },
  set: (value) => {
    emit("update:modelValue", JSON.parse(value));
  },
});
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-10" @close="emit('update:modelValue', false)">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              style="width: 950px; height: 700px"
              class="relative rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6"
            >
              <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500"
                  @click="emit('update:modelValue', false)"
                >
                  <span class="sr-only">Close</span>
                  <MaterialDesignIcon name="close" class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <DialogTitle class="flex text-lg font-medium leading-6 text-gray-900">
                <div class="sm:flex sm:items-center sm:justify-between">
                  <div class="sm:flex sm:space-x-5">
                    <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                      <p class="text-xl font-bold text-gray-900 sm:text-2xl">
                        Guidance Metadata
                      </p>
                      <p class="text-sm font-medium text-gray-600">
                        View and edit guidance metadata for the selected guidance.
                      </p>
                    </div>
                  </div>
                </div>
              </DialogTitle>

              <KodexaCodeEditor v-model="jsonRepresentation" language="json" style="height: 600px; width: 800px" />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
