<script lang="ts" setup>
import { Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { storeToRefs } from "pinia";
import type { PropType, Ref } from "vue";
import { onMounted, onUnmounted, ref } from "vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import { notify } from "notiwind";
import {Menu, SplitterPaneProps} from "@progress/kendo-vue-layout";
import { Splitter, TabStrip } from "@progress/kendo-vue-layout";
import KodexaDataFormView from "~/components/dataForm/kodexa-data-form-view.vue";
import KodexaDocumentView from "~/components/document/kodexa-document-view.vue";
import KodexaExecutionView from "~/components/execution/kodexa-execution-view.vue";
import router from "~/router/router";
import appStore from "~/store";
import { createSidecar } from "~/store/useSidecar";
import type { DataFormViewer, DocumentViewer, ExecutionViewer } from "~/store/useWorkspace";
import { usePlatform } from "~/store/usePlatform";
import { log } from "~/utils/logger";
import AssistantsPanel from "~/views/app/organizations/project/workspace/panels/assistants-panel.vue";
import CardPropertiesPanel from "~/views/app/organizations/project/workspace/panels/card-properties-panel.vue";
import DataFormsPanel from "~/views/app/organizations/project/workspace/panels/data-forms-panel.vue";
import ExceptionsPanel from "~/views/app/organizations/project/workspace/panels/exceptions-panel.vue";
import ExportsPanel from "~/views/app/organizations/project/workspace/panels/exports-panel.vue";
import LabelsPanel from "~/views/app/organizations/project/workspace/panels/labels-panel.vue";
import NavigationPanel from "~/views/app/organizations/project/workspace/panels/navigation-panel.vue";
import StorePanel from "~/views/app/organizations/project/workspace/panels/project-documents-panel.vue";
import SearchPanel from "~/views/app/organizations/project/workspace/panels/search-panel.vue";
import SidecarPanel from "~/views/app/organizations/project/workspace/panels/sidecar-panel.vue";
import TagPropertiesPanel from "~/views/app/organizations/project/workspace/panels/tag-properties-panel.vue";
import WorkspaceDocumentsPanel from "~/views/app/organizations/project/workspace/panels/workspace-documents-panel.vue";
import WorkspaceEmpty from "~/views/app/organizations/project/workspace/workspace-empty.vue";
import KodexaWorkspaceUpdateConfirm from "~/components/workspace/kodexa-workspace-update-confirm.vue";
import TaxonomyPanel from "~/views/app/organizations/project/workspace/panels/taxonomy-panel.vue";
import KodexaTextView from "~/components/text/kodexa-text-view.vue";
import ChannelPanel from "~/views/app/organizations/project/workspace/panels/channel-panel.vue";
import KodexaTruncatedText from "~/components/kodexa-truncated-text.vue";
import GuidancePanel from "~/views/app/organizations/project/workspace/panels/guidance-panel.vue";
import type { SelectedTag } from "~/components/document/document";
import WorkspaceConfigurationPanel
  from "~/views/app/organizations/project/workspace/panels/workspace-configuration-panel.vue";
import type { KeyboardShortcut } from "~/store/useKeyboard";
import WorkspaceViewMetadata from "~/views/app/organizations/project/workspace/workspace-view-metadata.vue";
import AuditNotesPanel from "~/views/app/organizations/project/workspace/panels/audit-notes-panel.vue";
import KodexaStepGraph from "~/components/execution/kodexa-step-graph.vue";
import {Popup} from "@progress/kendo-vue-popup";
import {cancelIcon, checkboxIcon, checkIcon} from "@progress/kendo-svg-icons";
import KodexaCostAnalysis from "~/components/execution/kodexa-cost-analysis.vue";

interface WorkspaceOptions {
  hideToolbar: boolean;
  hideClose: boolean;
  openPanel?: string;
  openSidecar?: boolean;
  openForm?: string;
}

const props = defineProps({
  workspaceOptions: {
    type: Object as PropType<WorkspaceOptions>,
    required: false,
    default: () => {
      return {
        hideToolbar: false,
        hideClose: false,
        openPanel: undefined,
        openSidecar: false,
        openForm: undefined,
      };
    },
  },
});

const {
  project,
} = storeToRefs(appStore.projectStore);

const {
  views,
  currentWorkspaceId,
  activeView,
  currentWorkspace,
  sidecarPanelOpen,
  activeSelectionView,
  workspaceSidebar,
  isDirty,
} = storeToRefs(appStore.workspaceStore);

// Handle the options
if (props.workspaceOptions.openPanel) {
  appStore.platformStore.currentSidebar = props.workspaceOptions.openPanel;
}

const platformStore = usePlatform();

const panes: Ref<SplitterPaneProps[]> = ref([
  {
    size: "30%",
    min: "20%",
    collapsible: true,
    collapsed: false,
    content: "sidebar",
  },
  {
    size: undefined,
    min: "20%",
    collapsible: false,
    content: "content",
  },
  {
    size: "30%",
    min: "20%",
    collapsible: true,
    collapsed: true,
    content: "sidecar",
  },
] as SplitterPaneProps[]);

function onSplitterChange(changeEvent: any) {
  if (changeEvent.newState) {
    panes.value = changeEvent.newState;

    if (panes.value.length === 3) {
      const open = !(panes.value[2].size === "0%" || panes.value[2].collapsed);
      if (open && !sidecarPanelOpen.value) {
        appStore.workspaceStore.toggleSidecar();
      } else if (!open && sidecarPanelOpen.value) {
        appStore.workspaceStore.toggleSidecar();
      }
    }
  }
}

watch(currentWorkspaceId, (value) => {
  if (value) {
    createSidecar(value, false);
    if (props.workspaceOptions.openSidecar && !sidecarPanelOpen.value) {
      appStore.workspaceStore.toggleSidecar();
    }

    if (props.workspaceOptions.openForm) {
      // We need to get the data form
      const { dataForms } = storeToRefs(appStore.projectStore);
      const dataForm = dataForms.value.find(df => df.ref === props.workspaceOptions.openForm);
      if (!dataForm) {
        notify({
          group: "error",
          title: "Unable to find data form",
          text: props.workspaceOptions?.openForm,
        }, 5000);
      } else {
        setTimeout(() => {
          log.info(`Adding data form ${dataForm.ref} to workspace`);
          appStore.workspaceStore.addDataForm(dataForm);
        }, 2000);
      }
    }
  }
}, {
  immediate: true,
});

const pollTimeout: Ref<any | undefined> = ref();

// Setup the shortcut keys we will have for the spatial document
const toggleSidecarShortcut = {
  key: "control+shift+o",
  altKey: "control+shift+o",
  description: "Toggle Sidecar",
  callback: () => {
    log.info("Toggling sidecar");
    toggleSidecar();
  },
} as KeyboardShortcut;

onMounted(() => {
  // We want to start a polling in the workspace while this component is active
  pollTimeout.value = setInterval(() => {
    appStore.workspaceStore.poll();
  }, 2000);
  const useKeyboardStore = useKeyboard();
  useKeyboardStore.addShortcut(toggleSidecarShortcut);
  document.body.addEventListener("click", bodyClick);
});

onBeforeUnmount(() => {
  if (pollTimeout.value) {
    clearTimeout(pollTimeout.value);
  }
  const useKeyboardStore = useKeyboard();
  useKeyboardStore.removeShortcut(toggleSidecarShortcut);
  document.body.removeEventListener("click", bodyClick);
});

onDeactivated(() => {
  log.info("Deactivated workspace");

  if (pollTimeout.value) {
    clearTimeout(pollTimeout.value);
  }

  const useKeyboardStore = useKeyboard();
  useKeyboardStore.removeShortcut(toggleSidecarShortcut);
});

watch(sidecarPanelOpen, (value) => {
  if (value) {
    if (panes.value && panes.value[2].size === "0%") {
      panes.value[2].size = "30%";
      panes.value[2].collapsed = false;
    } else if (panes.value) {
      panes.value[2].collapsed = false;
    }
  } else {
    if (panes.value) {
      panes.value[2].collapsed = true;
    }
  }
}, {
  immediate: true,
});

watch(
  () => platformStore.currentSidebar,
  (value) => {
    if (value && value !== "unset") {
      if (panes.value && panes.value[0].collapsed) {
        panes.value[0].collapsed = false;
      }
    } else if (panes.value) {
      panes.value[0].collapsed = true;
    }
  },
  { immediate: true },
);

function closeView(viewId: string) {
  appStore.workspaceStore.removeViewById(viewId);
}

function changeTab(event: any) {
  // Determine if we have a view for this tab
  const view = views.value[event.selected];
  if (view) {
    appStore.workspaceStore.setActiveView(view);
  }
}

function getTabTitle(view: DataFormViewer | ExecutionViewer | DocumentViewer) {
  return appStore.workspaceStore.getTitle(view);
}

function getViewMetadata(viewId: string) {
  const view = views.value.find(v => v.id === viewId);
  return appStore.workspaceStore.getViewMetadata(view);
}

const tabs = computed(() => {
  return views.value.map((view) => {
    return {
      id: view.id,
      title: getTabTitle(view),
      properties: {
        viewId: view.id,
        viewType: view.viewType,
      },
      closeable: true,
      content: "tabContent",
      titleRender: "titleRender",
      close: () => {
        closeView(view.id);
      },
    };
  });
});

function getViewIcon(viewId: string) {
  const icons = {
    document: "document",
    dataForm: "card-account-details",
    execution: "play-circle",
    text: "text",
    processingStep: "graph",
    costView: "currency-usd",
  };
  const view = views.value.find(v => v.id === viewId);
  if (view) {
    return icons[view.viewType];
  }
  return "document";
}

const currentTab = computed(() => {
  if (activeView.value) {
    return views.value.indexOf(activeView.value);
  }
  return undefined;
});

function toggleSidecar() {
  appStore.workspaceStore.toggleSidecar();
}

const viewComponents = {
  dataForm: KodexaDataFormView,
  document: KodexaDocumentView,
  execution: KodexaExecutionView,
  text: KodexaTextView,
  processingStep: KodexaStepGraph,
  costView: KodexaCostAnalysis,
};

function selectSidebarItem(item) {
  if (item.id === "sidecar") {
    appStore.workspaceStore.toggleSidecar();
    platformStore.currentSidebar = "unset";
  } else {
    platformStore.currentSidebar = item.id;
  }
}

async function backToProject() {
  await appStore.workspaceStore.showSaveWarning();
  router.push({
    name: "projectHome",
    params: { organizationId: project.value.organization.id, projectId: project.value.id },
  });
}

function prepareWorkspaceUpdate() {
  // Before we save the workspace we need to present the current state
  // so the user knows what they will be saving

  const workspaceUpdate = appStore.workspaceStore.buildWorkspaceUpdate();

  const dialog = createConfirmDialog(KodexaWorkspaceUpdateConfirm);
  dialog.onConfirm(async () => {
    try {
      await appStore.workspaceStore.saveWorkspace();
      await appStore.workspaceStore.saveWorkspaceObjects();
      await appStore.workspaceStore.saveUpdatedTaxonomies();
      await appStore.projectStore.retrainModels();

      notify({
        group: "generic",
        title: "Success",
        text: "Workspace changes saved",
      }, 3000);
    } catch (e) {
      notify({
        group: "error",
        title: "Error",
        text: "Workspace changes not saved, we encountered an error",
      }, 5000);
    }
  });
  dialog.reveal({
    workspaceUpdate,
    projectDirty: appStore.projectStore.isDirty,
    updatedTaxonomyRefs: appStore.workspaceStore.updatedTaxonomyRefs,
  });
}

watch(() => platformStore.currentSidebar, (value) => {
  // if we have a value then we need to set the splitter on the right to be open if it is not
  if (value && value !== "unset") {
    if (panes.value[0].collapsed) {
      panes.value[0].collapsed = false;
    }
  }
});

const selectedTag = ref<SelectedTag | undefined>(undefined);

function selectedTaxonTag(testTag: SelectedTag) {
  platformStore.currentSidebar = "unset";
  platformStore.currentSidebar = "taxonomy";
  selectedTag.value = testTag;
}

// Workspace scroll onhover

const SCROLL_SPEED = 3;
const BUFFER = 100;
const elementRef: Ref<HTMLElement | null> = ref(null);
const frameID: Ref<number | null> = ref(0);

function clearPrevFrame() {
  if (frameID.value !== null) {
    cancelAnimationFrame(frameID.value);
    frameID.value = null;
  }
}

function scroll({ direction }: { direction: string }) {
  const element = elementRef.value;
  if (element) {
    frameID.value = requestAnimationFrame(() => scroll({ direction }));
    element.scrollBy((direction === "left" ? -1 : 1) * SCROLL_SPEED, 0);
  }
}

function handler(e: MouseEvent) {
  const element = elementRef.value;
  if (element) {
    clearPrevFrame();
    const rect = element.getBoundingClientRect();

    if (e.clientX < rect.left + BUFFER) {
      scroll({ direction: "left" });
    } else if (e.clientX > rect.right - BUFFER) {
      scroll({ direction: "right" });
    }
  }
}

onMounted(() => {
  elementRef.value = document.getElementById("hoverScrollElements");

  if (elementRef.value) {
    elementRef.value.addEventListener("mousemove", handler);
    elementRef.value.addEventListener("mouseout", clearPrevFrame);
  }
});

onUnmounted(() => {
  const element = elementRef.value;
  if (element) {
    clearPrevFrame();
    element.removeEventListener("mousemove", handler);
    element.removeEventListener("mouseout", clearPrevFrame);
  }
});


const contextMenu = ref({
  show: false,
  offset: { left: 0, top: 0 },
  targetItem: null,
});

const menuItems = ref([
  { text: "Close", svgIcon: cancelIcon },
  { text: "Close All", svgIcon: cancelIcon },
  { text: "Close Others", svgIcon: cancelIcon },
]);

function handleContextMenu(e, item) {
  e.preventDefault();
  contextMenu.value.show = true;
  contextMenu.value.offset = { left: e.clientX, top: e.clientY };
  contextMenu.value.targetItem = item;
}

function onMenuSelect(e) {
  const item = contextMenu.value.targetItem;

  if (!item) {
    return;
  }

  switch (e.item.text) {
    case "Close":
      closeView(item);
      break;
    case "Close All":
      views.value.forEach((view) => {
        closeView(view.id);
      });

      break;
    case "Close Others":
      views.value.forEach((view) => {
        if (view.id !== item) {
          closeView(view.id);
        }
      });
      break;
  }
  contextMenu.value.show = false;
}

function bodyClick() {
  contextMenu.value.show = false;
}
</script>

<template>
  <div class="workspace-page">
    <KodexaTagPopup
      v-if="activeSelectionView && activeSelectionView.viewType === 'document'"
      :key="activeSelectionView.viewId"
      :active-selection-view="activeSelectionView"
      :kiosk-mode="false"
      @selected-taxon-tag="selectedTaxonTag"
    />
    <Toolbar v-if="!workspaceOptions.hideToolbar" class="mx-6 mb-1 border-0 bg-white">
      <ToolbarSpacer />
      <KodexaButton id="saveWorkspace" :disabled="!isDirty" icon="content-save" @click="prepareWorkspaceUpdate">
        Save Changes
      </KodexaButton>
      <KodexaButton
        v-if="!workspaceOptions.hideClose" id="closeWorkspace" icon="close" type="secondary"
        @click="backToProject"
      >
        Close
      </KodexaButton>
    </Toolbar>
    <Splitter
      v-if="panes"
      :panes="panes"
      orientation="horizontal"
      style="height: calc(100vh - 8rem); width: 100%; overflow-y: hidden;"
      @change="onSplitterChange"
    >
      <template #sidebar>
        <div style="height: calc(100vh - 8rem); width: 100%; overflow-y: hidden;">
          <div style="white-space: nowrap;">
            <ul
              id="hoverScrollElements"
              class="-mb-px flex overflow-scroll border-r bg-gray-50 text-center text-sm font-medium text-gray-500 dark:text-gray-400"
            >
              <li
                v-for="(item) in workspaceSidebar" :key="item.id"
                class="mx-2 mt-1"
                @click="selectSidebarItem(item)"
              >
                <a
                  :class="item.id === platformStore.currentSidebar ? 'inline-flex items-center justify-center p-2 text-theme-primary border-b-2 border-blue-600 rounded-t-sm active dark:text-blue-500 dark:border-blue-500 group' : 'inline-flex items-center justify-center p-2 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'"
                  class="text-xs"
                >
                  <MaterialDesignIcon
                    v-if="item?.icon" :name="item.icon" class="text-theme-primary mr-3"
                    size="18"
                  />
                  {{ item?.name }}
                </a>
              </li>
            </ul>
          </div>
          <div class="bg-gray-50" style="height: calc(100vh - 10rem); width: 100%; overflow-y: scroll">
            <NavigationPanel
              v-if="platformStore.currentSidebar === 'navigation' && activeView"
              :key="`${currentWorkspace.id}:${activeView.id}`"
              :view-id="activeView.id"
              @closed="platformStore.currentSidebar = 'unset'"
            />
            <WorkspaceConfigurationPanel
              v-if="platformStore.currentSidebar === 'workspaceConfig'"
              @closed="platformStore.currentSidebar = 'unset'"
            />
            <ExceptionsPanel
              v-if="platformStore.currentSidebar === 'exceptions' && activeView"
              :key="`${currentWorkspace.id}:${activeView.id}`"
              :view-id="activeView.id"
              @closed="platformStore.currentSidebar = 'unset'"
            />
            <GuidancePanel
              v-if="platformStore.currentSidebar === 'guidance'"
              :key="`${currentWorkspace.id}`"
              @closed="platformStore.currentSidebar = 'unset'"
            />
            <AuditNotesPanel
              v-if="platformStore.currentSidebar === 'auditNotes'"
              :key="`${currentWorkspace.id}`"
              @closed="platformStore.currentSidebar = 'unset'"
            />
            <AssistantsPanel
              v-if="platformStore.currentSidebar === 'assistants'"
              :key="`${currentWorkspace.id}`"
              @closed="platformStore.currentSidebar = 'unset'"
            />
            <ChannelPanel
              v-if="platformStore.currentSidebar === 'channel'"
              :key="`${currentWorkspace.id}`"
              entrypoint="workspace"
              @closed="platformStore.currentSidebar = 'unset'"
            />
            <ExportsPanel
              v-if="platformStore.currentSidebar === 'exports' && activeView"
              :view-id="activeView.id"
              @closed="platformStore.currentSidebar = 'unset'"
            />
            <LabelsPanel
              v-if="platformStore.currentSidebar === 'labels' && activeView"
              :key="`${currentWorkspace.id}:${activeView.id}`"
              :view-id="activeView.id"
              @closed="platformStore.currentSidebar = 'unset'"
            />
            <SearchPanel
              v-if="platformStore.currentSidebar === 'search' && activeView"
              :key="`${currentWorkspace.id}:${activeView.id}`"
              :view-id="activeView.id"
              @closed="platformStore.currentSidebar = 'unset'"
            />
            <TaxonomyPanel
              v-if="platformStore.currentSidebar === 'taxonomy'"
              v-model:selected-taxon-tag="selectedTag"
              @closed="platformStore.currentSidebar = 'unset'"
            />
            <TagPropertiesPanel
              v-if="platformStore.currentSidebar === 'properties' && activeView && activeView.viewType === 'document'"
              :key="`${currentWorkspace.id}:${activeView.id}`"
              :view-id="activeView.id"
              @closed="platformStore.currentSidebar = 'unset'"
            />
            <CardPropertiesPanel
              v-if="platformStore.currentSidebar === 'properties' && activeView && activeView.viewType === 'dataForm'"
              :key="`${currentWorkspace.id}:${activeView.id}`"
              :view-id="activeView.id"
              @closed="platformStore.currentSidebar = 'unset'"
            />
            <StorePanel
              v-if="platformStore.currentSidebar && platformStore.currentSidebar.startsWith('store-')"
              :key="platformStore.currentSidebar?.substring(6) + currentWorkspace.id"
              :no-close="workspaceOptions.hideClose"
              :store-ref="platformStore.currentSidebar?.substring(6)"
              @closed="platformStore.currentSidebar = 'unset'"
            />
            <WorkspaceDocumentsPanel
              v-if="platformStore.currentSidebar === 'workspaceDocuments'"
              :key="`${currentWorkspace.id}`"
              @closed="platformStore.currentSidebar = 'unset'"
            />
            <DataFormsPanel
              v-if="platformStore.currentSidebar === 'dataForms'"
              :key="`${currentWorkspace.id}`"
              @closed="platformStore.currentSidebar = 'unset'"
            />
          </div>
        </div>
      </template>
      <template #content>
        <div class="border-gray-800 bg-gray-50" style="height: calc(100vh - 8rem); width: 100%; overflow-y: hidden">
          <TabStrip :animation="false" :selected="currentTab" :tabs="tabs" class="pt-2" @select="changeTab">
            <template #titleRender="{ props }">
              <div class="flex" @contextmenu="handleContextMenu($event, props.id)">
                <VMenu :popper-triggers="['hover']">
                  <MaterialDesignIcon :name="getViewIcon(props.id)" class="mr-2" size="16" />
                  <template #popper>
                    <WorkspaceViewMetadata :view-metadata="getViewMetadata(props.id)" />
                  </template>
                </VMenu>
                <div class="mt-1 text-xs font-medium">
                  <KodexaTruncatedText :length="30" :text="props.title" />
                </div>
                <MaterialDesignIcon class="ml-2" name="close" size="16" @click="closeView(props.id)" />
              </div>
            </template>
            <template #tabContent="{ props }">
              <Component
                :is="viewComponents[props.dataItem.properties.viewType]"
                :key="props.dataItem.id"
                :view-id="props.dataItem.id"
              />
            </template>
          </TabStrip>

          <div v-if="!activeView" class="b-0 mt-20 flex flex-col items-center justify-center pb-48">
            <WorkspaceEmpty :workspace="currentWorkspace" />
          </div>
        </div>
        <Popup :show="contextMenu.show" :offset="contextMenu.offset">
          <Menu
            :items="menuItems"
            :vertical="true"
            @select="onMenuSelect"
          />
        </Popup>
      </template>
      <template #sidecar>
        <div class="h-full border-l border-gray-200">
          <SidecarPanel
            v-if="currentWorkspace" v-show="sidecarPanelOpen" :kiosk-mode="false" :no-close="workspaceOptions.hideClose"
            @closed="toggleSidecar"
          />
        </div>
      </template>
    </splitter>
  </div>
</template>

<style scoped>
.workspace-page ::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.workspace-page {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
