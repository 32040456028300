<script lang="ts" setup>
import type { GuidanceSet } from "~/model";

const props = defineProps<{
  guidanceSet: GuidanceSet;
}>();

const storage = computed(() => {
  return props.guidanceSet.storage;
});
</script>

<template>
  <div>
    <MaterialDesignIcon name="form-select" class="text-gray-400" size="20" />
    <div class="text-sm font-medium leading-6 text-gray-900">
      {{ guidanceSet.ref }}
    </div>
    <div>
      A
      {{ storage?.embeddingModelId }}
    </div>
    <div>
      B
      {{ storage?.embeddingModelId }}
    </div>
  </div>
</template>

<style scoped>

</style>
