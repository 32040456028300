<script setup lang="ts">
import { TreeView, TreeViewDragAnalyzer, TreeViewDragClue, processTreeViewItems } from "@progress/kendo-vue-treeview";
import type { PropType, Ref } from "vue";
import { Toolbar } from "@progress/kendo-vue-buttons";
import { storeToRefs } from "pinia";
import { Menu } from "@progress/kendo-vue-layout";
import { Popup } from "@progress/kendo-vue-popup";
import { v4 as uuidv4 } from "uuid";
import type { Taxon, Taxonomy } from "~/model";
import appStore from "~/store";
import { log } from "~/utils/logger";
import { copyToClipboard } from "~/utils/general";
import { getParent } from "~/utils/taxonomy-utils";

const props = defineProps({
  taxonomy: {
    type: Object as PropType<Taxonomy>,
    required: true,
  },
  groupOnly: {
    type: Boolean,
    required: false,
    default: false,
  },
  useSelection: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const emit = defineEmits(["selectTaxon", "deleteTaxon"]);

const expand = ref({
  ids: [] as string[],
  idField: "id",
});

const { tagMetadataMap } = storeToRefs(appStore.projectStore);

const taxonTree = computed(() => {
  if (props.groupOnly) {
    // We need to recursively filter out any taxons that are not groups
    const filterTaxons = function (taxons: Taxon[]) {
      const filteredTaxons: Taxon[] = [];
      taxons.forEach((taxon) => {
        if (taxon.group) {
          taxon.children = filterTaxons(taxon.children as Taxon[]);
          filteredTaxons.push(taxon);
        }
      });
      return filteredTaxons;
    };
    const copy = JSON.parse(JSON.stringify(props.taxonomy.taxons || []));
    return filterTaxons(copy);
  } else {
    return props.taxonomy.taxons || [];
  }
});

const treeDataItems = computed(() => {
  // We need to handle the taxonomy not being set
  if (props.taxonomy === undefined) {
    return [];
  }

  return processTreeViewItems(taxonTree.value, {
    expand: expand.value,
    childrenField: "children",
  });
});

const isDragging = ref(false);
const isDropped = ref(false);

function onExpandChange(event: any) {
  const index = expand.value.ids.indexOf(event.item.id);
  index === -1 ? expand.value.ids.push(event.item.id) : expand.value.ids.splice(index, 1);
}

const addRootTaxon = function () {
  appStore.projectStore.newTaxonInTaxonomy(props.taxonomy.ref, undefined, props.useSelection);
};

const selectTaxon = function (taxon: any) {
  emit("selectTaxon", taxon);
};

const addChildTaxon = function (taxon: any) {
  appStore.projectStore.newTaxonInTaxonomy(props.taxonomy.ref, taxon.id, props.useSelection);
  expand.value.ids.push(taxon.id as string);
};

const dragClue: Ref<any | null> = ref(null);
const dragOverCnt = ref(0);
const droppedItemId = ref<string | null>(null);

function onItemDragEnd(event: any) {
  isDragging.value = false;
  isDropped.value = false;
  if (dragClue.value) {
    dragClue.value.hide();
  }
  dragOverCnt.value = 0;
  const eventAnalyzer = new TreeViewDragAnalyzer(event).init();
  appStore.projectStore.moveTaxonBeforeTaxon(props.taxonomy, event.item, eventAnalyzer.itemId, eventAnalyzer.destItemId);

  // Add a class to the dropped item for styling purposes
  droppedItemId.value = event.item.id;
  setTimeout(() => {
    droppedItemId.value = null;
  }, 2000);
}

function onItemDragOver(event: any) {
  isDragging.value = true;
  dragOverCnt.value++;
  const eventAnalyzer = new TreeViewDragAnalyzer(event).init();
  if (dragClue.value && eventAnalyzer.isDropAllowed) {
    dragClue.value.show(
      event.pageY - 110,
      event.pageX,
      event.item.label,
      "k-i-insert-middle",
    );
  }
}

const editTaxonIds = ref([] as string[]);
const editTaxon = function (taxon: Taxon) {
  editTaxonIds.value.push(taxon.id as string);
};

const stopEditTaxon = function (taxon: Taxon) {
  editTaxonIds.value.splice(editTaxonIds.value.indexOf(taxon.id as string), 1);

  // We need to update the taxon in the store
  appStore.projectStore.findSelectedTaxonById(taxon.id, props.taxonomy.ref, taxon);
};

const tagTaxon = function (taxon: Taxon) {
  const { activeView } = appStore.workspaceStore;
  if (activeView) {
    log.info("Adding tag");
    const tagMetadata = tagMetadataMap.value.get(taxon.path);
    const useDocumentViewStore = createDocumentViewerStore(activeView.id);
    if (useDocumentViewStore?.selectionContext.selectedNodes) {
      useDocumentViewStore.addTag(tagMetadata, useDocumentViewStore?.selectionContext?.selectedNodes || [], {});
    }
  } else {
    log.warn("No document view store");
  }
};

const { user } = storeToRefs(appStore.userStore);

function copyTaxonomyJson() {
  const copyText = JSON.stringify(props.taxonomy, null, 2);
  copyToClipboard(copyText, "Copied Data Definition to Clipboard");
}

function generateNewUUIDs(taxon: Taxon): Taxon {
  const newTaxon: Taxon = {
    ...taxon,
    id: uuidv4(),
  };

  if (taxon.children && taxon.children.length > 0) {
    newTaxon.children = taxon.children.map(child => generateNewUUIDs(child));
  }

  return newTaxon;
}

async function pasteTaxonomyJson() {
  try {
    const text = await navigator.clipboard.readText();
    const parsedJson = JSON.parse(text);
    const newTaxons = parsedJson.taxons;

    if (Array.isArray(newTaxons)) {
      log.info("Pasting in the taxons");
      const updatedTaxons = newTaxons.map((taxon: Taxon) => generateNewUUIDs(taxon));

      updatedTaxons.forEach((taxon: Taxon) => {
        appStore.projectStore.newTaxonInTaxonomy(props.taxonomy.ref, undefined, props.useSelection, taxon);
      });
    }
  } catch (error) {
    log.error("Failed to paste taxonomy JSON", error);
  }
}

const { disabledTaxonomies } = storeToRefs(appStore.workspaceStore);

const toggleTaxonomy = function () {
  if (disabledTaxonomies.value.includes(props.taxonomy.ref)) {
    appStore.workspaceStore.enableTaxonomy(props.taxonomy);
  } else {
    appStore.workspaceStore.disableTaxonomy(props.taxonomy);
  }
};

const contextMenu = ref({
  show: false,
  offset: { left: 0, top: 0 },
  targetItem: null,
});

const menuSelectedItem = ref();
const menuItems = computed(() => {
  // we only want to show paste if it is a group and
  // show enable if disabled and vice versa
  // so the menu is dynamic
  const items = [
    {
      text: menuSelectedItem.value?.enabled ? "Disable" : "Enable",
    },
    {
      text: "Enable All",
    },
    {
      text: "Disable All",
    },
    {
      text: "Delete",
    },
    {
      text: "Copy",
    },
  ];

  if (menuSelectedItem.value?.group) {
    items.push({
      text: "Paste",
    });
  }

  return items;
});

function handleContextMenu(e, item) {
  e.preventDefault();
  contextMenu.value.show = true;
  menuSelectedItem.value = item;
  contextMenu.value.offset = { left: e.clientX, top: e.clientY };
  contextMenu.value.targetItem = item;
}

async function onMenuSelect(e) {
  const item = contextMenu.value.targetItem;
  switch (e.item.text) {
    case "Toggle Enabled":
      toggleEnabled(item);
      break;
    case "Enable All":
      toggleAllEnabled(item, true);
      break;
    case "Disable All":
      toggleAllEnabled(item, false);
      break;
    case "Delete":
      emit("deleteTaxon", item);
      break;
    case "Copy":
      copyToClipboard(JSON.stringify(item), "Copied Data Element to Clipboard");
      break;
    case "Paste":
      try {
        const text = await navigator.clipboard.readText();
        const newTaxon = JSON.parse(text);

        if (!item) {
          break;
        }

        // We need to go through the taxonomy and update all the id's to
        // new UUID's and recurse into children
        function updateIds(taxon: Taxon) {
          taxon.id = `${uuidv4()}`;
          if (taxon.children) {
            taxon.children.forEach((child) => {
              updateIds(child);
            });
          }
        }
        updateIds(newTaxon);
        newTaxon.label = `${newTaxon.label} (Copy)`;

        // if the item is a group then we want to paste it as a child
        if (item.group) {
          appStore.projectStore.newTaxonInTaxonomy(props.taxonomy.ref, item.id, props.useSelection, newTaxon);
        } else {
          appStore.projectStore.newTaxonInTaxonomy(props.taxonomy.ref, undefined, props.useSelection, newTaxon);
        }
      } catch (error) {
        log.error("Failed to paste taxonomy JSON", error);
      }
      break;
  }
  contextMenu.value.show = false;
}

function toggleEnabled(item) {
  // Find the taxon in the taxonomy
  const taxonomy = JSON.parse(JSON.stringify(props.taxonomy));
  const parent = getParent(taxonomy.taxons, item.id);

  if (parent) {
    const taxon = parent.children?.find(child => child.id === item.id);
    if (taxon) {
      taxon.enabled = !taxon.enabled;
      appStore.workspaceStore.updateTaxonomy(taxonomy);
    }
  } else {
    const taxon = taxonomy.taxons?.find(child => child.id === item.id);
    if (taxon) {
      taxon.enabled = !taxon.enabled;
      appStore.workspaceStore.updateTaxonomy(taxonomy);
    }
  }
}

function toggleAllEnabled(item, enabled) {
  // Copy the taxonomy so we can update it
  const taxonomy = JSON.parse(JSON.stringify(props.taxonomy));
  const parent = getParent(taxonomy.taxons, item.id);

  if (parent) {
    parent.children?.forEach((child) => {
      child.enabled = enabled;
    });
  } else {
    taxonomy.taxons?.forEach((child) => {
      child.enabled = enabled;
    });
  }
  appStore.workspaceStore.updateTaxonomy(taxonomy);
}

function bodyClick() {
  contextMenu.value.show = false;
}

onMounted(() => {
  document.body.addEventListener("click", bodyClick);
});

onUnmounted(() => {
  document.body.removeEventListener("click", bodyClick);
});
</script>

<template>
  <div>
    <TreeViewDragClue ref="dragClue" />
    <Toolbar class="mb-1 border-0 bg-gray-50 -ml-1" size="small">
      <KodexaButton v-if="!groupOnly" size="small" icon="plus" type="secondary" @click="addRootTaxon">
        Add Data Element
      </KodexaButton>
      <KodexaButton
        v-if="!groupOnly"
        type="secondary"
        :icon="disabledTaxonomies.includes(taxonomy.ref) ? 'eye-closed' : 'eye-outline'" size="small"
        @click="toggleTaxonomy"
      >
        Toggle Highlighting
      </KodexaButton>
      <KodexaButton
        v-if="user.showDeveloperTools"
        v-tooltip="`Copy JSON`"
        type="secondary" icon="content-copy"
        size="small" :fake-action="true"
        @click="copyTaxonomyJson"
      />
      <KodexaButton
        v-if="user.showDeveloperTools"
        v-tooltip="`Paste JSON`"
        type="secondary" icon="content-paste"
        size="small" @click="pasteTaxonomyJson"
      />
      <KodexaArticle class="lm-2 mb-1 mt-2" article-id="8729035" text="Learn more about defining data" :slide="false" />
    </Toolbar>
    <div
      v-if="taxonTree?.length === 0" class="mt-40 flex items-center justify-center"
      style="height: calc(100vh - 40rem)"
    >
      <div class="text-center">
        <MaterialDesignIcon aria-hidden="true" class="size-12 text-gray-400" name="sitemap-outline" />

        <h2 class="text-base font-semibold leading-6 text-gray-900">
          Start Creating Your Elements
        </h2>
        <p class="mt-5 px-16 text-sm text-gray-500">
          To begin, establish your initial data element, which serves as a foundational group of information.
        </p>
        <p class="mb-8 mt-3 px-16 text-sm text-gray-500">
          For instance, if your focus is on managing contract information, you might name your first data element
          "Contract." Under this element, you can then specify the details you intend to collect by adding elements such
          as "Contract Date".
        </p>

        <KodexaButton id="addRootTaxon" icon="plus" size="small" type="secondary" @click="addRootTaxon">
          Create a Data Element
        </KodexaButton>
      </div>
    </div>
    <TreeView
      v-else
      class="mt-1"
      :data-items="treeDataItems"
      text-field="label"
      children-field="children"
      :expand-icons="true"
      :draggable="true"
      item="item"
      @itemdragover="onItemDragOver"
      @itemdragend="onItemDragEnd"
      @expandchange="onExpandChange"
    >
      <template #item="{ props }">
        <div
          :class="{
            'dragging-indicator': isDragging,
            'dropped-indicator': droppedItemId === props.item.id,
          }"
          @contextmenu="handleContextMenu($event, props.item)"
        >
          <span>
            <MaterialDesignIcon
              v-if="editTaxonIds.indexOf(props.item.id) < 0 && props.item.taxonType !== 'SECTION' && props.item.valuePath !== 'FORMULA' && props.item.valuePath !== 'METADATA' && !props.item.group"
              name="tag" size="14" class="mr-1" :style="{
                color: props.item.color,
              }" @click="tagTaxon(props.item)"
            />
            <MaterialDesignIcon
              v-if="props.item.group"
              name="folder-table-outline" size="14" class="mr-1"
            />
            <MaterialDesignIcon
              v-if="props.item.taxonType === 'SECTION' && !props.item.group"
              name="minus" size="14" class="mr-1"
            />
            <MaterialDesignIcon
              v-if="props.item.valuePath === 'FORMULA' && !props.item.group"
              name="math-integral-box" size="14" class="mr-1"
            />
            <MaterialDesignIcon
              v-if="props.item.valuePath === 'METADATA' && !props.item.group"
              name="file-document-outline" size="14" class="mr-1"
            />
            <span
              v-if="editTaxonIds.indexOf(props.item.id) < 0"
              :class="{ 'text-gray-300': !props.item.enabled }"
              @click="selectTaxon(props.item)"
            >
              {{ props.item.label || "Missing Name" }}
            </span>
            <KodexaTextInput
              v-else
              v-model="props.item.label" name="taxonLabel" @dblclick="editTaxon(props.item)"
              @on-enter="stopEditTaxon(props.item)"
              @on-esc="stopEditTaxon(props.item)"
              @blur="stopEditTaxon(props.item)"
            />
            <MaterialDesignIcon
              v-if="(props.item.valuePath === 'VALUE' || props.item.valuePath === 'VALUE_OR_ALL_CONTENT') && props.item.semanticDefinition?.length > 0"
              class="ml-2 text-blue-400" name="message-outline" size="12" v-tooltip="`Prompt has been defined`"
            />
            <MaterialDesignIcon
              v-if="(props.item?.guideProperties?.guidanceKey)"
              class="ml-2 text-orange-400" name="directions-fork" size="12" v-tooltip="`Part of Guidance Key`"
            />

          </span>
          <MaterialDesignIcon
            v-if="editTaxonIds.indexOf(props.item.id) < 0 && props.item.group && !groupOnly"
            class="ml-4" name="plus" size="12" @click="addChildTaxon(props.item)"
          />
        </div>
      </template>
    </TreeView>
    <Popup :show="contextMenu.show" :offset="contextMenu.offset">
      <Menu
        :items="menuItems"
        :vertical="true"
        @select="onMenuSelect"
      />
    </Popup>
  </div>
</template>

<style scoped>
.dragging-indicator {
  opacity: 0.7;
  border-radius: 5px;
}

/* Styling for the dropped indicator */
.dropped-indicator {
  border: 2px solid blue;
  animation: blinking 1s infinite; /* Blinking animation */
  transition: border-color 0.3s ease-in-out; /* Border color transition */
}

/* Blinking animation keyframes */
@keyframes blinking {
  0% {
    border-color: blue;
  }
  50% {
    border-color: transparent;
  }
  100% {
    border-color: blue;
  }
}
</style>
